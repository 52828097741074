<template>
    <div class="dropdown is-up is-right" ref="dropdownMenuShare">
        <div class="dropdown-trigger">
            <font-awesome-icon aria-haspopup="true" aria-controls="dropdown-menu" :icon="{ prefix: 'fas', iconName: 'share-alt' }"/>
        </div>

        <div class="dropdown-menu margin-bottom-1 margin-right-share" id="dropdown-menu" role="menu">
            <div class="dropdown-content has-text-left padding-left-right">
                <social-sharing :networks="additionalSocialNetworks" :url="socialMediaUrl" inline-template
                                class="is-size-5">
                    <div>
                        <p class="text-primary">
                            <strong>
                                {{ $t('kmso.social_share_button.title') }}
                            </strong>
                        </p>

                        <div>
                            <network network="facebook" class="share-link pointer-hand">
                                Facebook
                            </network>
                        </div>

                        <div>
                            <network network="linkedin" class="share-link pointer-hand">
                                LinkedIn
                            </network>
                        </div>

                        <div>
                            <network network="xing" class="share-link pointer-hand">
                                XING
                            </network>
                        </div>

                        <div>
                            <network network="twitter" class="share-link pointer-hand">
                                Twitter
                            </network>
                        </div>

                        <div>
                            <network network="whatsapp" class="share-link pointer-hand">
                                WhatsApp
                            </network>
                        </div>
                    </div>
                </social-sharing>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SocialShareButton",
        props: [
            'socialMediaUrl',
        ],
        data() {
            return {
                additionalSocialNetworks: {
                    'xing' : {
                        'sharer': 'https://www.xing.com/spi/shares/new?url=@url',
                        'type': 'popup',
                    }
                }
            }
        },
        methods: {
            toggleDropdownMenuShare() {
                const dropdownMenuShare = this.$refs.dropdownMenuShare;
                if (dropdownMenuShare) {
                    if (dropdownMenuShare.classList.contains("is-active")) {
                        this.$refs.dropdownMenuShare.classList.remove("is-active");
                    } else {
                        this.$refs.dropdownMenuShare.classList.add("is-active");
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
