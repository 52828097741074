<template>
    <section>
        <div class="menu">
            <p class="menu-label">
                Allgemein
            </p>
            <ul class="menu-list">
                <li><a href="/backend">Dashboard</a></li>
            </ul>
            <p class="menu-label">
                Zeiterfassung
            </p>
            <ul class="menu-list">
                <li><a href="/backend/timerecording/overview">Übersicht</a></li>
                <li v-if="$auth.check(['admin'])"><a href="/backend/timerecording/administration">Verwaltung</a></li>
            </ul>
            <p class="menu-label">
                Blog
            </p>
            <ul class="menu-list">
                <li><a href="/backend/blog/new-post">Neuer Eintrag</a></li>
                <li><a href="/backend/blog/posts">Einträge</a></li>
            </ul>
            <p class="menu-label">
                System
            </p>
            <ul class="menu-list">
                <li v-if="$auth.check(['admin'])"><a href="/backend/system/statistics">Statistik</a></li>
                <li v-if="$auth.check(['admin'])"><a href="/backend/new-user">Neuer Nutzer</a></li>
                <li><a v-on:click="logout">Logout</a></li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: "BackendMenu",
        methods: {
            logout() {
                this.$auth.logout();
            }
        }
    }
</script>

<style scoped>
    .menu-label, li a {
        color: white;
    }
</style>
