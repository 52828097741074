<template>
    <div>
        <article class="message">
            <div class="message-body message-border-color-3">
                <div class="columns">
                    <div class="column is-12">
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet" background-shadow="shadow-message-background">
                            <p class="is-size-5-custom is-italic" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                Wir haben mit Jan Bickel und Roman Berneburg (KMSO) bereits verschiedene Projekte gemeistert, unter anderem nun auch einige im Bereich der Produkt- und Schnittstellenentwicklung einer Finanzierungsplattform. Die Entwicklungsleistungen waren stets überzeugend und den Erwartungen von mir, dem Product Owner, gerecht. Besonders positiv hat uns neben den überzeugenden Entwicklungsleistungen, die Erfahrungen im Projektmanagement und die hohe Leistungsbereitschaft gefallen. Jan und Roman nehmen Fristen sehr ernst und bringen viel Knowhow für die Umsetzung von komplexen Szenarien mit. Wir können KMSO nur empfehlen.
                            </p>
                        </read-more>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <p class="is-size-5-custom has-text-right">
                            <strong>Oliver Lenz</strong>
                        </p>
                        <p class="is-size-5-custom has-text-right">
                            Head of Key-Account- & Product-Management, Product Owner
                        </p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Referenzen-Kunden-Zitate-3',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
