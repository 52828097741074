<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/blog.svg" :alt="$t('kmso.blog.title_mobile')" class="is-transparent-3 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.blog.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.blog.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                             class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/blog.svg" :alt="$t('kmso.blog.title_desktop')" class="is-transparent-3 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.blog.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.blog.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                             class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.blog.social_media_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.blog.social_media_text') }}
                    </p>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Blog"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Blog"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.blog.news_title') }}
                    </h3>
                    <br>
                    <div v-if="isNaN(this.page) || this.page > this.pages || this.page < 0"
                         class="is-size-5-custom"
                         :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <i18n path="kmso.blog.no_page_found"
                              tag="p">
                            <strong slot="link1">{{ this.page }}</strong>
                        </i18n>
                    </div>
                    <div v-else>
                        <div v-for="blogPost in blogPosts">
                            <blog-post-teaser
                                :is-mobile="isMobile"
                                :is-tablet="isTablet"
                                :headline="blogPost.headline"
                                :text="blogPost.text"
                                :image-url="blogPost.image_url"
                                :url="blogPost.url"
                                :likes="blogPost.likes"
                                :comments="blogPost.comments"
                                :author="blogPost.author"
                                :date="blogPost.date">
                            </blog-post-teaser>
                        </div>
                        <br>
                        <div class="has-text-centered" v-if="this.pages > 1">
                            <pagination
                                v-if="this.isMobile && !this.isTablet"
                                v-model="page"
                                :page-count="pages"
                                :click-handler="loadBlogPosts"
                                :margin-pages="0"
                                prev-text="&#60;"
                                next-text="&#62;"
                                container-class="blog-pagination"
                                page-link-class="button pagination-button pagination-background-shadow is-size-6-custom margin-left-pagination margin-right-pagination"
                                prev-link-class="button pagination-button pagination-background-shadow is-size-6-custom margin-right-pagination"
                                next-link-class="button pagination-button pagination-background-shadow is-size-6-custom margin-left-pagination"
                                active-class="pagination-button-active"
                                :no-li-surround="true">
                            </pagination>
                            <pagination
                                v-else-if="this.isTablet"
                                v-model="page"
                                :page-count="pages"
                                :click-handler="loadBlogPosts"
                                prev-text="&#60;"
                                next-text="&#62;"
                                container-class="blog-pagination"
                                page-link-class="button pagination-button pagination-background-shadow is-size-4-custom margin-left-pagination margin-right-pagination"
                                prev-link-class="button pagination-button pagination-background-shadow is-size-4-custom margin-right-pagination"
                                next-link-class="button pagination-button pagination-background-shadow is-size-4-custom margin-left-pagination"
                                active-class="pagination-button-active"
                                :no-li-surround="true">
                            </pagination>
                            <pagination
                                v-else
                                v-model="page"
                                :page-count="pages"
                                :click-handler="loadBlogPosts"
                                prev-text="&#60;"
                                next-text="&#62;"
                                container-class="blog-pagination"
                                page-link-class="button pagination-button pagination-background-shadow is-size-5-custom transition-up move-up-on-hover margin-left-pagination margin-right-pagination"
                                prev-link-class="button pagination-button pagination-background-shadow is-size-5-custom transition-up move-up-on-hover margin-right-pagination"
                                next-link-class="button pagination-button pagination-background-shadow is-size-5-custom transition-up move-up-on-hover margin-left-pagination"
                                active-class="pagination-button-active"
                                :no-li-surround="true">
                            </pagination>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import BlogPostTeaser from "../components/backend/BlogPostPreview";
    import Pagination from 'vuejs-paginate';

    export default {
        name: "Blog",
        props: [
            'isMobile',
            'isTablet',
        ],
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            BlogPostTeaser,
            Pagination
        },
        data() {
            return {
                blogPosts: null,
                page: this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1),
                pages: 0,
                siteLoaded: null,
                protocolAndHost: window.location.protocol + "\/\/" + window.location.host,
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_blog"),
                        "item": this.protocolAndHost + this.$route.path
                    }]
                }
            }
        },
        methods: {
            loadBlogPostsAndCalculatePage() {
                this.loadBlogPosts(this.page);
            },
            loadBlogPosts(pageNumber) {
                const pathName = "/blog/seite/" + pageNumber;
                const pageUrl = this.protocolAndHost + pathName;

                this.updateJsonld(pageUrl);

                if (isNaN(pageNumber) || pageNumber < 0) {
                    this.page = pageNumber;

                    this.siteIsLoaded();
                }
                else {
                    this.page = Number(pageNumber);

                    if (this.$route.path !== pathName) {
                        this.$router.replace(pathName);
                    }

                    window.axios.get('blog/load-posts', {
                        params: {
                            language: this.$i18n.locale,
                            page: this.page
                        },
                    })
                    .then(response => {
                        const data = response.data;

                        this.blogPosts = data.blogPosts;
                        this.pages = data.pages;

                        this.siteIsLoaded();
                    })
                    .catch(error => {
                        this.siteIsLoaded();
                    });
                }
            },
            updateJsonld(pageUrl) {
                this.jsonld = {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_blog"),
                        "item": pageUrl
                    }]
                };
            },
            siteIsLoaded() {
                if(!this.siteLoaded) {
                    this.$allComponentsLoaded(this.isMobile);
                    this.siteLoaded = true;
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.loadBlogPosts(this.page);
            });
        },
    }
</script>

<style scoped>

</style>
