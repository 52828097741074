<template>
    <figure class="image is-1by1" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
        <div onclick="window.open('https://twitter.com/kmso_de/', '_blank')" class="pointer-hand">
            <img src="/img/social/twitter.png" alt="Twitter" :class="{ 'blog-social-icons-mobile': this.isMobile }">
        </div>
    </figure>
</template>

<script>
    export default {
        name: 'Blog-4',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
