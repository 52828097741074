<template>
    <section>
        <div class="columns">
            <div class="column is-12">
                <article class="message">
                    <div class="message-body message-border-color-9">
                        <div class="columns is-vcentered">
                            <div v-if="imageUrl" class="column is-2">
                                <router-link :to="{ name: 'blogpost', params: { url: url }, hash: '#allgemein'}">
                                    <img :src="imageUrl" :alt="$t('kmso.blog.img_1')" class="is-horizontal-center border-radius-4">
                                </router-link>
                            </div>
                            <div class="column has-text-left"
                                 :class="{ 'is-10': imageUrl, 'is-12': !imageUrl}">
                                <div class="columns">
                                    <div class="column is-12">
                                        <router-link :to="{ name: 'blogpost', params: { url: url }, hash: '#allgemein'}">
                                        <h4 class="is-size-4-custom">
                                            <strong>
                                                {{ headline }}
                                            </strong>
                                        </h4>
                                        </router-link>
                                        <p class="is-size-5-custom">
                                            <i>
                                                {{ $t('kmso.blog.blog_post_meta', {date: date, name: author}) }}
                                            </i>
                                        </p>
                                    </div>
                                </div>
                                <div class="columns is-vcentered">
                                    <div class="column is-12">
                                        <div class="content is-size-5-custom">
                                            <span v-html="text"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-6">
                                        <div class="is-size-5 has-text-left">
                                            <router-link :to="{ name: 'blogpost', params: { url: url }, hash: '#allgemein'}">
                                                {{ $t('kmso.blog.blog_post_read_full_article') }} &#8811;
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="is-size-5 has-text-centered display-flex"
                                             :class="{ 'icons-left': this.isMobile && !this.isTablet, 'icons-right': !this.isMobile || this.isTablet }">
                                            <div class="box icon-box-blog padding-left-right">
                                                <font-awesome-icon class="margin-right-1" :icon="{ prefix: 'fas', iconName: 'comment-alt' }"/>{{ comments }}
                                            </div>
                                            <div class="space-between-columns-half"></div>
                                            <div class="box icon-box-blog padding-left-right">
                                                <font-awesome-icon class="margin-right-1" :icon="{ prefix: 'fas', iconName: 'thumbs-up' }"/>{{ likes }}
                                            </div>
                                            <div class="space-between-columns-half"></div>
                                            <div class="box icon-box-blog share-button pointer-hand"
                                                 @click="openSocialShareMenu">
                                                <social-share-button ref="socialShareButtons" :social-media-url="socialMediaUrl" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
    import SocialShareButton from "../SocialShareButton";

    export default {
        name: "BlogPostTeaser",
        props: [
            'isMobile',
            'isTablet',
            'headline',
            'text',
            'imageUrl',
            'url',
            'comments',
            'likes',
            'author',
            'date',
        ],
        components: {
            SocialShareButton
        },
        data() {
            return {
                socialMediaUrl: null
            }
        },
        methods: {
            openSocialShareMenu() {
                this.$refs.socialShareButtons.toggleDropdownMenuShare();
            }
        },
        mounted() {
            this.socialMediaUrl = window.location.protocol + "\/\/" + window.location.host + "/" + this.$route.name + "/" + this.url;
        }
    }
</script>

<style scoped>

</style>
