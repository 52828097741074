<template>
    <section>
        <blog-comment
            v-for="blogComment in blogComments"
            v-bind:key="blogComment.id"
            :comment="blogComment.comment"
            :name="blogComment.name"
            :time="blogComment.time"
            :date="blogComment.date"
        />
    </section>
</template>

<script>
    import BlogComment from "./BlogComment";

    export default {
        name: "BlogComments",
        components: {
            BlogComment,
        },
        props: [
            'postId'
        ],
        data() {
            return {
                blogComments: null,
            }
        },
        methods: {
            getAmount() {
                return this.blogComments.length;
            },
            loadComments() {
                window.axios.get('blog/load-comments', {
                    params: {
                        id: this.postId
                    }
                })
                .then(response => {
                    this.blogComments = response.data;

                    this.$emit('loaded');
                });
            }
        },
        mounted() {
            this.loadComments();
        }
    }
</script>

<style scoped>

</style>
