<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/projektmanagement12.svg" :alt="$t('kmso.project_management.title_mobile')" class="is-transparent-4 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.project_management.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.project_management.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/projektmanagement12.svg" :alt="$t('kmso.project_management.title_desktop')" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.project_management.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.project_management.subtitle_desktop_1') }}
                                <br>
                                {{ $t('kmso.project_management.subtitle_desktop_2') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.main_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.main_text') }}
                    </p>
                    </read-more>
                    <br>
                    <div v-if="!this.isTablet">
                        <carousel
                            carousel="Projektmanagement-1"
                            :is-mobile="this.isMobile"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel v-if="!this.isMobile"
                                  carousel="Projektmanagement-2"
                                  :is-mobile="this.isMobile"
                                  :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                    <div v-else>
                        <carousel
                            carousel="Projektmanagement-1"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel
                            carousel="Projektmanagement-2"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                </div>
            </div>
        </section>
        <section id="projektinitiierung" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_initiation_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_initiation_text') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-1 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.project_initiation_box_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement11.svg" :alt="$t('kmso.project_management.main_box_1_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.project_initiation_box_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>{{ $t('kmso.project_management.project_initiation_summary_title') }}</strong>
                    </h4>
                    <br>
                    <carousel
                          carousel="Projektmanagement-Fazit-1"
                          :is-mobile="this.isMobile"
                          :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="projektplanung" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_planning_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_planning_text') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.project_planning_box_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement2.svg" :alt="$t('kmso.project_management.main_box_2_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.project_planning_box_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.project_management.project_planning_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Projektmanagement-Fazit-2"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="projektcontrolling" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_controlling_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.project_controlling_text') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-3 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.project_controlling_box_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement3.svg" :alt="$t('kmso.project_management.main_box_3_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.project_controlling_box_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.project_management.project_controlling_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Projektmanagement-Fazit-3"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="methodik" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.methodology_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.methodology_text_1') }}
                        <span @click="showModal('princeAndPmbok')"
                              class="text-with-tooltip pointer-hand">
                            {{ $t('kmso.project_management.methodology_text_2') }}
                        </span>
                        {{ $t('kmso.project_management.methodology_text_3') }}
                    </p>
                    </read-more>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-4 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>{{ $t('kmso.project_management.methodology_box_1_title') }}</p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement1.svg" :alt="$t('kmso.project_management.main_box_4_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.methodology_box_1_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-4 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>{{ $t('kmso.project_management.methodology_box_2_title') }}</p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement.svg" :alt="$t('kmso.project_management.main_box_4_alt_2')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.methodology_box_2_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>{{ $t('kmso.project_management.methodology_summary_title') }}</strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Projektmanagement-Fazit-4"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="fachkenntnisse" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.expertise_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-grey-background">
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.expertise_text') }}
                    </p>
                    </read-more>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-5 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>{{ $t('kmso.project_management.expertise_box_title') }}</p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement4.svg" :alt="$t('kmso.project_management.main_box_5_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.expertise_box_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>{{ $t('kmso.project_management.expertise_summary_title') }}</strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Projektmanagement-Fazit-5"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="branche" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.business_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.project_management.business_text') }}
                    </p>
                    </read-more>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-6 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.business_box_1_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement5.svg" :alt="$t('kmso.project_management.main_box_6_alt_1')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.business_box_1_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-6 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.business_box_2_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement6.svg" :alt="$t('kmso.project_management.main_box_6_alt_2')" class="is-horizontal-center">
                                </div>

                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.business_box_2_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-6 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.project_management.business_box_3_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/projektmanagement7.svg" :alt="$t('kmso.project_management.main_box_6_alt_3')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.project_management.business_box_3_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.project_management.business_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Projektmanagement-Fazit-6"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-left" background-custom="grey-background" />
        <WaveFooter background-custom="grey-background" />
        <script v-html="jsonld" type="application/ld+json"></script>

        <div class="modal" :class="{ 'is-active': modalToolTip }">
            <div class="modal-background" @click="closeModal"></div>
            <div class="modal-content" :class="{ 'unset-width': !this.isMobile }">
                <article class="message">
                    <div class="message-header is-size-5-custom">
                        <p ref="modalHeader" class="is-size-5-custom"></p>
                        <button id="modalClose"
                                class="delete big-delete"
                                aria-label="close"
                                @click="closeModal"/>
                    </div>
                    <div class="message-body is-size-5-custom">
                        <p ref="modalBody" class="is-size-5-custom has-text-left"></p>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';
    import InteresseKlein from '../components/interesse-klein';

    export default {
        name: "Projekt-Management",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                modalToolTip: false,
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_services"),
                        "item": window.location.protocol + "\/\/" + window.location.host + "/leistungen"
                    }, {
                        "@type": "ListItem",
                        "position": 2,
                        "name": this.$i18n.t("kmso.app.menu_services_1"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
            InteresseKlein,
        },
        methods: {
            showModal(modalName) {
              this.modalToolTip = !this.modalToolTip;

              if (modalName === 'princeAndPmbok') {
                  const modalHeader = this.$refs.modalHeader;
                  if (modalHeader) {
                      modalHeader.innerText = this.$t('kmso.project_management.methodology_modal_title');
                  }

                  const modalBody = this.$refs.modalBody;
                  if (modalBody) {
                      modalBody.innerText = this.$t('kmso.project_management.methodology_modal_text');
                  }
              }
            },
            closeModal() {
                this.modalToolTip = false;
            },
        },
        created() {
            document.addEventListener('keyup', function (evt) {
                if (evt.key === "Escape") {
                    const closeModal = document.getElementById("modalClose");
                    if (closeModal) {
                        closeModal.click();
                    }
                }
            });
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        }
    }
</script>

<style scoped>

</style>
