<template>
    <figure class="image is-1by1" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
        <div onclick="window.open('https://www.qbound.io', '_blank')" class="pointer-hand">
            <img src="/img/referenzen-qbound.png" alt="qbound">
        </div>
    </figure>
</template>

<script>
    export default {
        name: 'Referenzen-Kunden-5',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
