<template>
    <section>
        <b-table
            :data="data"
            paginated
            per-page="15"
        >
            <template slot-scope="props">
                <b-table-column
                    field="headline"
                    label="Überschrift"
                    sortable
                >
                    {{ props.row.headline }}
                </b-table-column>

                <b-table-column
                    field="published"
                    label="Kommentare"
                    sortable
                >
                    {{ props.row.comments }}
                </b-table-column>

                <b-table-column
                    field="published"
                    label="Likes"
                    sortable
                >
                    {{ props.row.likes }}
                </b-table-column>

                <b-table-column
                    field="published"
                    label="Aufrufe"
                    sortable
                >
                    {{ props.row.views }}
                </b-table-column>

                <b-table-column
                    field="date"
                    label="Datum"
                    sortable
                >
                    {{ props.row.date }}
                    <span v-if="props.row.published" class="icon has-text-success">
                        <i class="fas fa-check"/>
                    </span>

                    <span v-else class="icon has-text-warning">
                        <i class="fas fa-times"/>
                    </span>
                </b-table-column>

                <b-table-column
                    field="published"
                    label="Veröffentlicht"
                    sortable
                >
                    <font-awesome-icon v-if="props.row.published" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                    <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                </b-table-column>

                <b-table-column
                    label="Aktionen"
                >
                    <a class="button is-small" :href="'/backend/blog/edit-post/' + props.row.id">
                        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'pen' }"/>
                    </a>
                    <button class="button is-small" v-on:click="openDeleteModal(props.row.id)">
                        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash' }"/>
                    </button>
                </b-table-column>
            </template>
        </b-table>

        <div class="modal" :class="{ 'is-active': deleteModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Blogeintrag löschen</p>
                    <button class="delete" aria-label="close" v-on:click="closeDeleteModal"/>
                </header>
                <section class="modal-card-body">
                    Soll der Blogeintrag wirklich gelöscht werden?
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" v-on:click="deletePost">Löschen</button>
                    <button class="button is-success" v-on:click="closeDeleteModal">Abbrechen</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Posts",
        data() {
            return {
                data: [],
                columns: [
                    {
                        field: 'headline',
                        label: 'Überschrift',
                    },
                    {
                        field: 'published',
                        label: 'Veröffentlicht',
                    },
                    {
                        field: 'date',
                        label: 'Datum',
                        centered: true,
                    },
                ],
                deleteModal: false,
                postForDeletion: null,
            }
        },
        mounted() {
            this.loadBlogPosts();
        },
        methods: {
            loadBlogPosts() {
                window.axios.get('backend/blog/posts', {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()},
                })
                    .then(response => {
                        this.data = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            closeDeleteModal() {
                this.postForDeletion = null;
                this.deleteModal = false;
            },
            openDeleteModal(id) {
                console.log('delete-id', id);
                this.deleteModal = true;
                this.postForDeletion = id;
            },
            deletePost() {
                window.axios.post('backend/blog/delete-post', {
                    id: this.postForDeletion,
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()},
                })
                    .then(response => {
                        this.loadBlogPosts();

                        this.deleteModal = false;
                        this.postForDeletion = null;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
        }
    }
</script>

<style scoped>

</style>
