<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/softwareentwicklung.svg" :alt="$t('kmso.software_development.title_mobile')" class="is-transparent-4 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.software_development.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.software_development.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/softwareentwicklung.svg" :alt="$t('kmso.software_development.title_desktop')" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.software_development.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.software_development.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.main_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.software_development.main_text') }}
                        </p>
                    </read-more>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Software"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Software"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.main_title_1') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.main_text_1') }}
                    </p>
                    <br>
                    <div v-if="!this.isTablet">
                        <carousel
                            carousel="Software-Technologien-1"
                            :is-mobile="this.isMobile"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel v-if="!this.isMobile"
                                  carousel="Software-Technologien-2"
                                  :is-mobile="this.isMobile"
                                  :is-tablet="this.isTablet">
                        </carousel>
                        <carousel v-if="!this.isMobile"
                                  carousel="Software-Technologien-3"
                                  :is-mobile="this.isMobile"
                                  :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                    <div v-else>
                        <carousel
                            carousel="Software-Technologien-1"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel
                            carousel="Software-Technologien-2"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel
                            carousel="Software-Technologien-3"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                </div>
            </div>
        </section>
        <section id="webentwicklung" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.web_development_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.software_development.web_development_text') }}
                        </p>
                    </read-more>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.software_development.web_development_box_1_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/softwareentwicklung1.svg" :alt="$t('kmso.software_development.websites')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.software_development.web_development_box_1_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.software_development.web_development_box_2_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/softwareentwicklung2.svg" :alt="$t('kmso.software_development.web_applications')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.software_development.web_development_box_2_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.software_development.web_development_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Software-Fazit-1"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="mobileapps" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.mobile_apps_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.mobile_apps_text') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-3 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.software_development.mobile_apps_box_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/softwareentwicklung3.svg" :alt="$t('kmso.software_development.mobile_apps')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <i18n path="kmso.software_development.mobile_apps_box_text_1"
                                              tag="p">
                                            <a slot="link1" href="https://de.statista.com/statistik/daten/studie/184332/umfrage/marktanteil-der-mobilen-betriebssysteme-in-deutschland-seit-2009/" target="_blank" rel="nofollow">statista</a>
                                        </i18n>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.software_development.mobile_apps_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Software-Fazit-2"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <section id="desktopanwendungen" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.desktop_apps_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.software_development.desktop_apps_text') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-6 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.software_development.desktop_apps_box_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/softwareentwicklung4.svg" :alt="$t('kmso.software_development.desktop_applications')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.software_development.desktop_apps_box_text') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.software_development.desktop_apps_summary_title') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Software-Fazit-3"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br>
                    <InteresseKlein :is-mobile="this.isMobile" :is-tablet="this.isTablet" />
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-left" background-custom="grey-background" />
        <WaveFooter background-custom="grey-background" />
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';
    import InteresseKlein from '../components/interesse-klein';

    export default {
        name: "Software-Entwicklung",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                modalToolTip: false,
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_services"),
                        "item": window.location.protocol + "\/\/" + window.location.host + "/leistungen"
                    }, {
                        "@type": "ListItem",
                        "position": 2,
                        "name": this.$i18n.t("kmso.app.menu_services_2"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
            InteresseKlein,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
