<template>
    <div>
        <router-link :to="{ name: 'projekt-management', hash: '#branche' }">
            <article class="message" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                <div class="message-body message-border-color-6">
                    <div class="columns is-vcentered">
                        <div class="column is-2">
                            <figure class="image is-3by2">
                                <img src="/img/projektmanagement10.svg" :alt="$t('kmso.services.main_box_1_element_6')">
                            </figure>
                        </div>
                        <div class="column is-10"
                             :class="{ 'has-text-centered': this.isMobile, 'has-text-left': !this.isMobile }">
                            <p :class="{ 'min-text-height-mobile-2 is-vertical-center add-horizontal-center': this.isMobile }" class="is-size-4-custom text-blue">{{ $t('kmso.services.main_box_1_element_6') }} &#8811;</p>
                            <p :class="{ 'min-text-height-mobile-3 is-vertical-center add-horizontal-center': this.isMobile }" class="is-size-5-custom">{{ $t('kmso.services.main_box_1_element_6_subtitle') }}</p>
                        </div>
                    </div>
                </div>
            </article>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Leistungen-Projektmanagement-6',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
