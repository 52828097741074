<template>
    <section>
        <h1 class="title">Willkommen im KMSO-Backend!</h1>

        <p>Hier gibt es die Möglichkeit neue Blogeinträge zu verfassen, diese zu editieren und weiterhin können
        Zeiten für Arbeitsschritte gespeichert werden.</p>
    </section>
</template>

<script>
    export default {
        name: "Index",
        mounted() {}
    }
</script>

<style scoped>

</style>
