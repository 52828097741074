<template>
    <div>
        <header>
            <div id="navbar-wrapper">
                <nav class="navbar is-fixed-top shadow-effect" role="navigation" aria-label="main navigation">
                    <div class="navbar-brand">
                        <router-link class="navbar-logo navbar-item navbar-router-link" :to="{ name: 'startseite' }" @click.native="$moveToAnchor('startseite')" :class="{ 'display-none': this.navbarDesktop }">
                            <img src="/img/kmso.svg" alt="KMSO" width="112" height="28">
                        </router-link>

                        <a id="navbar-burger" role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarKMSO"
                           @click="this.toggleMenu">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div id="navbar-menu" class="navbar-menu">
                        <ul class="navbar-start navbar-center-menu">
                            <li class="navbar-logo navbar-item navbar-item-center navbar-router-link" :class="{ 'display-none': !this.navbarDesktop }">
                                <router-link :to="{ name: 'startseite' }" @click.native="$moveToAnchor('startseite')">
                                    <img src="/img/kmso.svg" alt="KMSO" width="112" height="28" class="is-vertical-center">
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'startseite' }" @click.native="$moveToAnchor('startseite')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_home') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link navbar-item has-dropdown navbar-item-center-dropdown" :class="{ 'is-active': this.showServices }"
                                @mouseover="this.showAllServices" @mouseout="this.hideAllServices">
                                <router-link id="navbar-leistungen" class="navbar-item height-100p" :to="{ name: 'leistungen' }" @click.native="$moveToAnchor('leistungen')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_services') }}</span>
                                </router-link>

                                <ul class="navbar-dropdown navbar-item-center-dropdown navbar-dropdown-no-padding navbar-dropdown-background" :class="{ 'is-boxed': !this.isMobileComputed }">
                                    <li class="navbar-router-link">
                                        <router-link class="navbar-item" :to="{ name: 'projekt-management' }" @click.native="$moveToAnchor('projekt-management')">
                                            <span class="is-size-6">{{ $t('kmso.app.menu_services_1') }}</span>
                                        </router-link>
                                    </li>
                                    <li class="navbar-router-link">
                                        <router-link class="navbar-item" :to="{ name: 'software-entwicklung' }" @click.native="$moveToAnchor('software-entwicklung')">
                                            <span class="is-size-6">{{ $t('kmso.app.menu_services_2') }}</span>
                                        </router-link>
                                    </li>
                                    <li class="navbar-router-link">
                                        <a href="https://boost-your-app.com" target="_blank" class="navbar-item">
                                            <span class="is-size-6">Boost Your App</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'referenzen' }" @click.native="$moveToAnchor('referenzen')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_references') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'karriere' }" @click.native="$moveToAnchor('karriere')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_career') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'ueber-kmso' }" @click.native="$moveToAnchor('ueber-kmso')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_about') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'blog', params: { page: 1 } }" @click.native="$moveToAnchor('blog')"
                                             :class="{ 'router-link-active': this.$route.name === 'blog' || this.$route.name === 'blogpost' }">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_blog') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item-center navbar-router-link">
                                <router-link class="navbar-item navbar-item-center width-100p" :to="{ name: 'kontakt' }" @click.native="$moveToAnchor('kontakt')">
                                    <span class="is-size-5">{{ $t('kmso.app.menu_contact') }}</span>
                                </router-link>
                            </li>

                            <li class="navbar-item navbar-item-center">
                                <label class="sliderSwitch">
                                    <input class="sliderCheckbox"
                                           type="checkbox"
                                           @change="this.toggleLanguage"
                                           :checked="this.language === this.languages[1]">
                                    <span class="sliderObject slider langSliderIcon"></span>
                                </label>
                            </li>

                            <li class="navbar-item navbar-item-center">
                                <label class="sliderSwitch">
                                    <input class="sliderCheckbox"
                                           type="checkbox"
                                           @change="this.toggleTheme"
                                           :checked="this.$theme === this.themes[0]">
                                    <span class="sliderObject slider themeSliderIcon"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

        <div id="content-wrapper"
             @click="this.contentWrapperClick">
            <main>
                <transition
                    name="fade"
                    mode="out-in">
                        <router-view
                            :is-mobile="this.isMobileComputed"
                            :is-tablet="this.isTabletComputed"
                            ref="routerView" />
                </transition>
            </main>

            <footer-element
                v-if="this.$pageFullyLoaded"
                :is-mobile="this.isMobileComputed"
                :is-tablet="this.isTabletComputed" />

            <scroll-to-element v-if="this.$pageFullyLoaded" />
        </div>

        <easy-contact
            ref="easyContactMessage"
            :is-mobile="this.isMobileComputed"
            :is-tablet="this.isTabletComputed"
            @openCloseEasyContact="this.closeNavbarMenu" />
    </div>
</template>

<script>
   export default {
        props: [
            'isMobile',
            'isTablet',
            'initialLanguage',
        ],
        data() {
            return {
                themes: ['dark', 'light'],
                language: this.initialLanguage,
                languages: ['de', 'en'],
                isActive: 'is-active',
                navbarItemHover: 'navbar-item-hover',
                showServices: null,
                isMobileComputed: !!this.isMobile,
                isTabletComputed: !!this.isTablet,
                navbarDesktop: null,
            }
        },
        methods: {
            getNavbarWrapper() {
                return document.getElementById('navbar-wrapper');
            },
            getMenu() {
                return document.getElementById('navbar-menu');
            },
            getBurger() {
                return document.getElementById('navbar-burger');
            },
            getNavbarLeistungen() {
                return document.getElementById('navbar-leistungen');
            },
            showAllServices() {
              this.showServices = true;
              this.showOrHideNavbarLeistungenBackgroundAndTextColor(true);
            },
            hideAllServices() {
                this.showServices = null;
                this.showOrHideNavbarLeistungenBackgroundAndTextColor(false);
            },
            showOrHideNavbarLeistungenBackgroundAndTextColor(show) {
                const navbarLeistungen = this.getNavbarLeistungen();
                if (navbarLeistungen) {
                    if (show) {
                        navbarLeistungen.classList.add(this.navbarItemHover);
                    } else {
                        navbarLeistungen.classList.remove(this.navbarItemHover);
                    }
                }
            },
            closeContactMessage() {
                this.$refs.easyContactMessage.closeEasyContactMessage();
            },
            toggleMenu() {
                const menu = this.getMenu();
                const burger = this.getBurger();

                if (burger.classList.contains(this.isActive)) {
                    burger.classList.remove(this.isActive);
                } else {
                    burger.classList.add(this.isActive);
                    this.closeContactMessage();
                }

                if (menu.classList.contains(this.isActive)) {
                    menu.classList.remove(this.isActive);
                } else {
                    menu.classList.add(this.isActive);
                }
            },
            toggleLanguage() {
                if (this.language === this.languages[0]) {
                    this.language = this.languages[1];
                } else if (this.language === this.languages[1]) {
                    this.language = this.languages[0];
                }

                this.changeLanguage();
            },
            toggleTheme() {
                if (this.$theme === this.themes[0]) {
                    this.$theme = this.themes[1];
                } else if (this.$theme === this.themes[1]) {
                    this.$theme = this.themes[0];
                }

                this.$changeTheme();
            },
            changeLanguage() {
                this.$changeLanguageTop(this.language);

                this.$updateMetaData(this.$route, true);

                if (this.$route.name === 'blogpost') {
                    this.$refs.routerView.loadBlogPost();
                } else if (this.$route.name === 'blog') {
                    this.$refs.routerView.loadBlogPostsAndCalculatePage();
                }
            },
            initListeners() {
                const navbarWrapper = this.getNavbarWrapper();
                const menu = this.getMenu();
                const burger = this.getBurger();

                if (menu && burger) {
                    if (navbarWrapper) {
                        const links = navbarWrapper.querySelectorAll(".navbar-router-link");
                        links.forEach(link => {
                            link.addEventListener("click", function () {
                                burger.classList.remove("is-active");
                                menu.classList.remove("is-active");
                            });
                        });
                    }
                }
            },
            contentWrapperClick() {
                if (this.isMobileComputed) {
                    this.closeNavbarMenu();
                    this.closeContactMessage();
                }
            },
            closeNavbarMenu() {
                const menu = this.getMenu();
                const burger = this.getBurger();

                if (menu && burger) {
                    burger.classList.remove("is-active");
                    menu.classList.remove("is-active");
                }
            },
            customWindowResize() {
                this.displayLogo();
            },
            displayLogo() {
                const burger = this.getBurger();

                this.navbarDesktop = window.getComputedStyle(burger).display === 'none';
            }
        },
        created () {
           window.addEventListener('orientationchange', this.$windowOrientationEvent);
           window.addEventListener("resize", this.customWindowResize);

           if (!this.isMobileComputed && navigator.maxTouchPoints > 0 || this.isTabletComputed) {
               this.isMobileComputed = true;
               this.isTabletComputed = true;
               document.getElementById('font-css').href = window.location.protocol + "\/\/" + window.location.host + "/css/font-sizes-mobile.css";
           }
        },
        destroyed () {
           window.removeEventListener('orientationchange', this.$windowOrientationEvent);
           window.removeEventListener("resize", this.customWindowResize);
        },
        mounted() {
            this.displayLogo();
            this.initListeners();
        }
    }
</script>

<style scoped>
    .fade-leave-active {
        transition-duration: 0s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter-active {
        transition-duration: .3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
