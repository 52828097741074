<template>
    <section class="hero is-success is-fullheight">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">
                    Seite wurde nicht gefunden
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>
