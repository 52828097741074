<template>
    <section>
        <div class="columns">
            <div class="column is-offset-one-quarter is-half">
                <div class="field">
                    <label class="label">Beschreibung der Tätigkeit</label>
                    <div class="control">
                        <textarea class="textarea" :class="{'is-danger': !descriptionValid}" v-model="description"></textarea>
                    </div>
                </div>

                <div v-if="!descriptionValid" class="notification is-primary">
                    Die Beschreibung kann nicht leer sein.
                </div>

                <div class="columns">
                    <div class="column is-narrow">
                        <div class="field">
                            <label class="label">Stunden</label>
                            <div class="select">
                                <select v-model="hours">
                                    <option v-for="n in 24" :value="n-1">{{ n-1 }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="label">Minuten</label>
                            <div class="select">
                                <select v-model="minutes">
                                    <option v-for="n in 12" :value="(n-1)*5">{{ (n-1)*5 }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!timeValid" class="notification is-primary">
                    Die angegebene Zeit ist nicht korrekt. Eine Dauer von 0 Minuten kann nicht vermerkt werden.
                </div>

                <button class="button is-primary" v-on:click="saveTimerecord">Speichern</button>
            </div>
        </div>

        <b-table
            :data="data"
            paginated
            per-page="15"
        >
            <template slot-scope="props">
                <b-table-column
                    field="headline"
                    label="Beschreibung der Tätigkeit"
                    sortable
                >
                    {{ props.row.description }}
                </b-table-column>

                <b-table-column
                    field="time_logged"
                    label="Dauer"
                    width="100"
                >
                    {{ props.row.time_logged }}
                </b-table-column>

                <b-table-column
                    field="date"
                    label="Datum"
                    sortable
                    width="150"
                >
                    {{ props.row.date }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
export default {
    name: "Overview",
    data() {
        return {
            description: null,
            hours: 0,
            minutes: 0,
            selected: null,
            descriptionValid: true,
            timeValid: true,
            data: [],
        }
    },
    mounted() {
        this.loadTimerecords();
    },
    methods: {
        saveTimerecord() {
            this.timeValid = true;
            this.descriptionValid = true;

            let timeInMinutes = this.hours * 60 + this.minutes;

            if(timeInMinutes === 0) {
                this.timeValid = false;
            }

            if(this.description === null || this.description.trim().length === 0) {
                this.descriptionValid = false;
            }

            if(this.timeValid && this.descriptionValid) {
                window.axios
                    .post('backend/timerecording/create-timerecord', {
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()},
                        description: this.description,
                        timeLogged: timeInMinutes,
                    })
                    .then(response => {
                        this.description = null;
                        this.hours = 0;
                        this.minutes = 0;
                        this.loadTimerecords();
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            }
        },
        loadTimerecords() {
            window.axios
                .get('backend/timerecording/timerecords-for-user', {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
}
</script>

<style scoped>

</style>
