<template>
    <section class="hero is-medium foreground" :class="this.backgroundCustom">
        <div class="hero-body">
            <div class="container" :data-aos="this.direction">
                <h3 class="title title-custom has-text-centered">
                    {{ $t('kmso.interest.interested') }}
                </h3>
                <div class="columns is-centered is-vcentered">
                    <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                        <article class="message">
                            <div class="message-body message-border-color-9">
                                <figure class="image is-3by2">
                                    <img src="/img/interesse.svg" :alt="$t('kmso.interest.interest')">
                                </figure>
                            </div>
                        </article>
                    </div>
                    <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                        <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <p class="is-size-5-custom">
                                {{ $t('kmso.interest.text') }}
                            </p>
                        </div>
                        <br>
                        <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <router-link class="is-size-5-custom" :to="{ name: 'kontakt' }">
                                {{ $t('kmso.interest.url') }} &#8811;
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Interesse',
        props: [
            'isMobile',
            'isTablet',
            'backgroundCustom',
            'direction',
        ],
    }
</script>
