<template>
    <div>
        <div onclick="window.open('https://laravel.com/', '_blank')" class="pointer-hand">
            <article class="message" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                <div class="message-body message-border-color-3">
                    <figure class="image is-1by1">
                        <img src="/img/technologie-laravel.png" alt="Laravel">
                    </figure>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Software-Technologien-1',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
