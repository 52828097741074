<template>
    <b-tabs v-on:change="loadPublishingInformation">
        <b-tab-item label="Deutsch">
            <div class="field">
                <label class="label">Überschrift</label>
                <div class="control">
                    <input class="input" type="text" v-model="headline.de">
                </div>
            </div>

            <div class="field">
                <label class="label">Inhalt</label>
                <vue-editor ref="editorDe"/>
            </div>

            <div class="field">
                <label class="label">Link</label>
                <div class="control">
                    <input class="input" type="text" v-model="urlText.de" v-on:keyup="generateUrl('de')">
                </div>
                <div class="control">
                    Ergebnis: {{ url.de }}
                </div>
            </div>

            <div class="button is-primary" v-on:click="savePost('de')">Speichern</div>
        </b-tab-item>

        <b-tab-item label="Englisch">
            <div class="field">
                <label class="label">Überschrift</label>
                <div class="control">
                    <input class="input" type="text" v-model="headline.en">
                </div>
            </div>

            <div class="field">
                <label class="label">Inhalt</label>
                <vue-editor ref="editorEn"/>
            </div>

            <div class="field">
                <label class="label">Link</label>
                <div class="control">
                    <input class="input" type="text" v-model="urlText.en" v-on:keyup="generateUrl('en')">
                </div>
                <div class="control">
                    Ergebnis: {{ url.en }}
                </div>
            </div>

            <button class="button is-primary" v-on:click="savePost('en')">Speichern</button>
            <button class="button is-primary" v-on:click="loadTemplate('de', 'en')">Vorlage laden (deutsch)</button>
        </b-tab-item>

        <b-tab-item label="Vorschau">
            <div class="field">
                <label class="label">Bild</label>

                <div class="columns">
                    <div class="column">
                        <figure class="image is-256x256">
                            <img :src="preview.imageUrl" alt="Keine Vorschau verfügbar">
                        </figure>
                    </div>

                    <div class="column">
                        <vue-dropzone
                            ref="previewDropzone"
                            id="dropzone-teaser-image"
                            :options="dropzoneOptions"
                            v-on:vdropzone-success="previewImageUploadSuccess"
                            v-on:vdropzone-error="previewImageUploadError"
                        />

                        <button class="button" v-on:click="clearPreviewDropzone">Bereich leeren</button>
                    </div>
                </div>
            </div>

            <div class="field">
                <label class="label">Deutscher Text</label>

                <vue-editor
                    ref="deEditorPreview"
                    is-preview="true"
                />
            </div>

            <div class="field">
                <label class="label">Englischer Text</label>

                <vue-editor
                    ref="enEditorPreview"
                    is-preview="true"
                />
            </div>

            <button class="button is-primary" v-on:click="savePreview">Speichern</button>
        </b-tab-item>

        <b-tab-item label="Veröffentlichung">
            <table class="table">
                <tr>
                    <th>Sprache</th>
                    <th>Überschrift</th>
                    <th>Text</th>
                    <th>Link</th>
                    <th>Vorschau</th>
                    <th>Status</th>
                </tr>

                <tr>
                    <td>Deutsch</td>
                    <td>
                        <font-awesome-icon v-if="publish.de.status.headline" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.de.status.text" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.de.status.url" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.de.status.preview" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.de.valid" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                </tr>

                <tr>
                    <td>Englisch</td>
                    <td>
                        <font-awesome-icon v-if="publish.en.status.headline" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.en.status.text" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.en.status.url" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.en.status.preview" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                    <td>
                        <font-awesome-icon v-if="publish.en.valid" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                        <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                    </td>
                </tr>
            </table>

            <div class="columns">
                <div class="column is-narrow">
                    Vorschaubild
                </div>
                <div class="column">
                    <font-awesome-icon v-if="publish.status.preview_image" :icon="{ prefix: 'fas', iconName: 'check' }"/>
                    <font-awesome-icon v-else :icon="{ prefix: 'fas', iconName: 'times' }"/>
                </div>
            </div>

            <button v-if="!publish.is_published" class="button is-primary" :disabled="!publish.valid"
                    v-on:click="publishPost">Veröffentlichen
            </button>
            <button v-if="publish.is_published" class="button is-primary" v-on:click="unpublishPost">Veröffentlichung
                aufheben
            </button>
        </b-tab-item>
    </b-tabs>
</template>

<script>
    import VueEditor from "../../../components/backend/VueEditor";
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    const PUBLISHING_PAGE = 3;

    export default {
        components: {
            VueEditor,
            vueDropzone: vue2Dropzone,
        },
        name: "BlogEditor",
        props: [
            'postId',
            'isEdit',
        ],
        data() {
            return {
                headline: {
                    de: null,
                    en: null,
                },
                url: {
                    de: null,
                    en: null,
                },
                urlText: {
                    de: null,
                    en: null,
                },
                publish: {
                    de: {
                        text: 0,
                        headline: 0,
                        status: false,
                    },
                    en: {
                        text: 0,
                        headline: 0,
                        status: false,
                    },
                    status: false,
                },
                dropzoneOptions: {
                    url: '/api/v1/backend/blog/image-preview-upload',
                    thumbnailWidth: 150,
                    thumbnailHeight: 150,
                    maxFilesize: 2,
                    headers: { "Authorization": "Bearer " + this.$auth.token() },
                    maxFiles: 1,
                    acceptedFiles: 'image/jpeg, image/gif, image/png',
                    params: {
                        id: this.postId,
                    }
                },
                preview: {
                    imageUrl: null,
                }
            }
        },
        mounted() {
            if (this.isEdit) {
                this.loadPostContent();
            }
        },
        methods: {
            savePreview() {
                let dePreview = this.$refs.deEditorPreview.getHtml();
                let enPreview = this.$refs.enEditorPreview.getHtml();

                console.log(dePreview, enPreview);

                window.axios.post('backend/blog/save-preview-texts', {
                    id: this.postId,
                    de_preview: dePreview,
                    en_preview: enPreview,
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log('error', error.response);
                })
            },
            clearPreviewDropzone() {
                this.$refs.previewDropzone.removeAllFiles(true);
            },
            previewImageUploadSuccess(file, response) {
                this.preview.imageUrl = response.url;
                this.$refs.previewDropzone.removeAllFiles(true);
            },
            previewImageUploadError(file, message, xhr) {
                console.log('error', file, message, xhr);
            },
            generateUrl(language) {
                let url = this.urlText.de;

                if (language === 'en') {
                    url = this.urlText.en;
                }

                if (url) {
                    url = url.toLowerCase();
                    url = url.replace(/ /g, '-');

                    console.log('URL', url);

                    switch (language) {
                        case 'de':
                            this.url.de = url;
                            break;
                        case 'en':
                            this.url.en = url;
                            break;
                    }
                } else {
                    switch (language) {
                        case 'de':
                            this.url.de = null;
                            break;
                        case 'en':
                            this.url.en = null;
                            break;
                    }
                }
            },
            loadPostContent() {
                window.axios.get('backend/blog/load-post', {
                    params: {
                        id: this.postId,
                    },
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                    .then(response => {
                        console.log(response);
                        let data = response.data;

                        this.setHeadline('de', data.de_headline);
                        this.setHeadline('en', data.en_headline);

                        this.setEditorContent('de', data.de_text);
                        this.setEditorContent('en', data.en_text);

                        this.setUrl('de', data.de_url, data.de_url_text);
                        this.setUrl('en', data.en_url, data.en_url_text);

                        this.preview.imageUrl = data.preview_url;
                        this.setPreviewEditorContent('de', data.de_preview);
                        this.setPreviewEditorContent('en', data.en_preview);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            savePost(language) {
                let headline = this.headline[language];
                let url = this.url[language];
                let urlText = this.urlText[language];

                let text = this.$refs.editorDe.getHtml();

                if (language === 'en') {
                    text = this.$refs.editorEn.getHtml();
                }

                console.log('headline', headline);
                console.log('text', text);

                window.axios
                    .post('backend/blog/save-post', {
                        id: this.postId,
                        headline: headline,
                        text: text,
                        url: url,
                        url_text: urlText,
                        language: language,
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                    })
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            },
            loadTemplate(languageFrom, languageTo) {
                window.axios
                    .get('backend/blog/load-post-template', {
                        params: {
                            id: this.postId,
                            language: languageFrom,
                        },
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                    })
                    .then(response => {
                        console.log(response.data);
                        let headline = response.data.headline;
                        let text = response.data.text;
                        let url = response.data.url;
                        let urlText = response.data.urlText;

                        this.setHeadline(languageTo, headline);
                        this.setEditorContent(languageTo, text);
                        this.setUrl(languageTo, url, urlText);
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            },
            setHeadline(language, headline) {
                this.headline[language] = headline;
            },
            setEditorContent(language, text) {
                if (language === 'de') {
                    this.$refs.editorDe.setContent(text);
                } else if (language === 'en') {
                    this.$refs.editorEn.setContent(text);
                }
            },
            setPreviewEditorContent(language, text) {
                if(language === 'de') {
                    this.$refs.deEditorPreview.setContent(text);
                } else if(language === 'en') {
                    this.$refs.enEditorPreview.setContent(text);
                }
            },
            setUrl(language, url, urlText) {
                this.url[language] = url;
                this.urlText[language] = urlText;
            },
            loadPublishingInformation(index) {
                if (index === PUBLISHING_PAGE) {
                    console.log('post-id', this.postId);

                    window.axios.get('backend/blog/pre-publish-data', {
                        params: {
                            id: this.postId
                        },
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                    })
                        .then(response => {
                            this.publish = response.data;
                            console.log(this.publish);

                        })
                        .catch(error => {
                            console.log('error', error);
                        })
                }
            },
            publishPost() {
                window.axios.post('backend/blog/publish-post', {
                    id: this.postId,
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.loadPublishingInformation(PUBLISHING_PAGE);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            unpublishPost() {
                window.axios.post('backend/blog/unpublish-post', {
                    id: this.postId,
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.loadPublishingInformation(PUBLISHING_PAGE);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>

<style scoped>
    .is-256x256 {
        width: 256px;
        height: 256px;
    }
</style>
