<template>
    <section>
        <h3 class="subtitle">Page Views</h3>

        <b-table
            v-if="dataPageViews.length > 0"
            :data="dataPageViews"
            paginated
            per-page="10"
        >
            <template slot-scope="props">
                <b-table-column
                    field="views"
                    label="Aufrufe"
                    sortable
                    numeric
                >
                    {{ props.row.views }}
                </b-table-column>

                <b-table-column
                    field="path"
                    sortable
                    label="Pfad"
                >
                    {{ props.row.path }}
                </b-table-column>

                <b-table-column
                    field="last_update"
                    sortable
                    label="Letzte Aktualisierung"
                >
                    {{ props.row.last_update }}
                </b-table-column>
            </template>
        </b-table>

        <h3 class="subtitle">Visitors</h3>

        <b-table
            v-if="dataVisitors.length > 0"
            :data="dataVisitors"
        >
            <template slot-scope="props">
                <b-table-column
                    field="id"
                    label="Aufruf"
                    numeric
                >
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column
                    field="path"
                    label="Pfad"
                >
                    {{ props.row.path }}
                </b-table-column>

                <b-table-column
                    field="time"
                    label="Zeit"
                >
                    {{ props.row.time }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    export default {
        name: "Statistics",
        data() {
            return {
                dataPageViews: [],
                dataVisitors: []
            }
        },
        mounted() {
            this.loadPageViews();
            this.loadVisitors();

            setInterval(this.loadPageViews, 10000);
            setInterval(this.loadVisitors, 2000);
        },
        methods: {
            loadPageViews() {
                axios.get('backend/statistics/page-views')
                    .then(result => {
                        this.dataPageViews = result.data;
                    });
            },
            loadVisitors() {
                axios.get('backend/statistics/visitors')
                    .then(result => {
                        this.dataVisitors = result.data;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
