<template>
    <blog-editor v-if="blogPostId" :post-id="blogPostId" />
</template>

<script>
    import BlogEditor from "./BlogEditor";

    export default {
        name: "NewEntry",
        components: {
            BlogEditor
        },
        data() {
            return {
                blogPostId: null
            }
        },
        mounted() {
            this.createNewPost();
        },
        methods: {
            createNewPost() {
                console.log(this.$auth.token());

                window.axios
                    .get('backend/blog/create-new-post', {
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                    })
                    .then(response => {
                        this.blogPostId = response.data.id;
                        console.log(this.blogPostId);
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            }
        }
    }
</script>

<style scoped>

</style>
