<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/datenschutz.svg" :alt="$t('kmso.privacy_policy.title_mobile')" class="is-transparent-4 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.privacy_policy.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.privacy_policy.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/datenschutz.svg" :alt="$t('kmso.privacy_policy.title_desktop')" class="is-transparent-4 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.privacy_policy.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.privacy_policy.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">{{ $t('kmso.privacy_policy.main_title') }}</h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_1') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_2') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_3') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_4') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_5') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_6') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_7') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_8') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_9') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_10') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_11') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_12') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_13') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_14') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_15') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_16') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_17') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_18') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_19') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_20') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_21') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_22') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_23') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_24') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_25') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_26') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_27') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_28') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_29') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_30') }}</p>
                            <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <img src="/img/kmso.svg" alt="KMSO" width="112" height="28">
                                <br>
                                KMSO IT-Dienstleistungen GmbH
                                <br>
                                Steinbacher Straße 2
                                <br>
                                65760 Eschborn
                                <br>
                                {{ $t('kmso.footer.germany') }}
                                <br>
                            </p>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <div class="icon-color-2">
                                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }"/> {{ $t('kmso.footer.telephone') }}
                                </div>
                                <a href="tel:+4961739979821" rel="nofollow">06173 / 997 98 21</a>
                            </div>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <div class="icon-color-1">
                                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }"/> {{ $t('kmso.footer.whatsapp') }}
                                </div>
                                <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow">0159 / 064 79 447</a>
                            </div>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <div class="icon-color-3">
                                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }"/> {{ $t('kmso.footer.email') }}
                                </div>
                                <p><a href="mailto:kontakt@kmso.de" rel="nofollow">kontakt@kmso.de</a></p>
                            </div>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <div class="icon-color-2">
                                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'map-marker-alt' }"/> {{ $t('kmso.footer.website') }}
                                </div>
                                <p><router-link :to="{ name: 'startseite' }">www.kmso.de</router-link></p>
                            </div>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_32') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_33') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_34') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_35') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_36') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_37') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_38') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_39') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_40') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_49') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_50') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_51') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_52') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_53') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_54') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_55') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_56') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_57') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_58') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_59') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_60') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_61') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_62') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_63') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_64') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_65') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_66') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_67') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_68') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_69') }}</li>
                            </ul>
                            <p>{{ $t('kmso.privacy_policy.text_70') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_71') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_72') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_73') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_74') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_75') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_76') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_77') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_78') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_79') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_80') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_81') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_82') }}</li>
                            </ul>
                            <p>{{ $t('kmso.privacy_policy.text_83') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_84') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_85') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_86') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_87') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_88') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_89') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_90') }}</li>
                            </ul>
                            <p>{{ $t('kmso.privacy_policy.text_91') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_92') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_93') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_94') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_95') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_96') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_97') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_98') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_99') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_100') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_101') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_102') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_103') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_104') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_105') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_106') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_107') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_108') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_109') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_110') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_111') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_112') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_113') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_114') }}</p>
                            <i18n path="kmso.privacy_policy.text_115"
                                tag="p">
                                <a slot="link" href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="nofollow">https://developers.facebook.com/docs/plugins/</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_116') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_117') }}</p>
                            <i18n path="kmso.privacy_policy.text_118"
                                  tag="p">
                                <a slot="link" href="https://www.facebook.com/about/privacy/" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy/</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_119') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_120') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_121') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_122') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_123') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_124') }}</p>
                            <i18n path="kmso.privacy_policy.text_125"
                                  tag="p">
                                <a slot="link1" href="https://help.instagram.com/155833707900388" target="_blank" rel="nofollow">https://help.instagram.com/155833707900388</a>
                                <a slot="link2" href="https://www.instagram.com/about/legal/privacy/" target="_blank" rel="nofollow">https://www.instagram.com/about/legal/privacy/</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_126') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_127') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_128') }}</p>
                            <i18n path="kmso.privacy_policy.text_129"
                                  tag="p">
                                <a slot="link" href="https://developer.linkedin.com/plugins" target="_blank" rel="nofollow">https://developer.linkedin.com/plugins</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_130') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_131') }}</p>
                            <i18n path="kmso.privacy_policy.text_132"
                                  tag="p">
                                <a slot="link1" href="https://www.linkedin.com/psettings/guest-controls" target="_blank" rel="nofollow">https://www.linkedin.com/psettings/guest-controls</a>
                                <a slot="link2" href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/cookie-policy</a>
                                <a slot="link3" href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/privacy-policy</a>
                                <a slot="link4" href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/cookie-policy</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_140') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_141') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_142') }}</p>
                            <i18n path="kmso.privacy_policy.text_143"
                                  tag="p">
                                <a slot="link" href="https://dev.xing.com/plugins" target="_blank" rel="nofollow">https://dev.xing.com/plugins</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_144') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_145') }}</p>
                            <i18n path="kmso.privacy_policy.text_146"
                                  tag="p">
                                <a slot="link1" href="https://www.xing.com/privacy" target="_blank" rel="nofollow">https://www.xing.com/privacy</a>
                                <a slot="link2" href="https://www.xing.com/app/share?op=data_protection" target="_blank" rel="nofollow">https://www.xing.com/app/share?op=data_protection</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_133') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_134') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_135') }}</p>
                            <i18n path="kmso.privacy_policy.text_136"
                                  tag="p">
                                <a slot="link" href="https://about.twitter.com/de/resources/buttons" target="_blank" rel="nofollow">https://about.twitter.com/de/resources/buttons</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_137') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_138') }}</p>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_139"
                                  tag="p">
                                <a slot="link" href="https://twitter.com/privacy?lang=de" target="_blank" rel="nofollow">https://twitter.com/privacy?lang=de</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_139"
                                  tag="p">
                                <a slot="link" href="https://twitter.com/privacy?lang=en" target="_blank" rel="nofollow">https://twitter.com/privacy?lang=en</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_159') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_160') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_161') }}</p>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_162"
                                  tag="p">
                                <a slot="link" href="https://www.youtube.com/intl/de/about/" target="_blank" rel="nofollow">https://www.youtube.com/intl/de/about/</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_162"
                                  tag="p">
                                <a slot="link" href="https://www.youtube.com/intl/en/about/" target="_blank" rel="nofollow">https://www.youtube.com/intl/en/about/</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_163') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_164') }}</p>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_165"
                                  tag="p">
                                <a slot="link" href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="nofollow">https://www.google.de/intl/de/policies/privacy/</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_165"
                                  tag="p">
                                <a slot="link" href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="nofollow">https://www.google.com/intl/en/policies/privacy/</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_166') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_167') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_168') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_169') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_170') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_171') }}</p>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_172"
                                  tag="p">
                                <a slot="link1" href="https://www.tiktok.com/legal/privacy-policy?lang=de" target="_blank" rel="nofollow">https://www.tiktok.com/legal/privacy-policy?lang=de</a>
                                <a slot="link2" href="https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=de" target="_blank" rel="nofollow">https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=de</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_172"
                                  tag="p">
                                <a slot="link1" href="https://www.tiktok.com/legal/privacy-policy?lang=en" target="_blank" rel="nofollow">https://www.tiktok.com/legal/privacy-policy?lang=en</a>
                                <a slot="link2" href="https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=en" target="_blank" rel="nofollow">https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=en</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_173') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_174') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_175') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_176') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_177') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_178') }}</p>
                            <i18n path="kmso.privacy_policy.text_179"
                                  tag="p">
                                <a slot="link1" href="https://github.com/site/privacy" target="_blank" rel="nofollow">https://github.com/site/privacy</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_180') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_181') }}</p>
                            <i18n path="kmso.privacy_policy.text_182"
                                  tag="p">
                                <a slot="link1" href="https://www.privacyshield.gov/program-overview" target="_blank" rel="nofollow">https://www.privacyshield.gov/program-overview</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_183') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_186') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_187') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_188"
                                      tag="li">
                                    <a slot="link1" href="https://www.facebook.com/about/privacy/" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy/</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_189"
                                      tag="li">
                                    <a slot="link1" href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="nofollow">https://www.facebook.com/settings?tab=ads</a>
                                    <a slot="link2" href="http://www.youronlinechoices.com" target="_blank" rel="nofollow">http://www.youronlinechoices.com</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_190"
                                      tag="li">
                                    <a slot="link1" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC" target="_blank" rel="nofollow">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_191"
                                      tag="li">
                                    <a slot="link1" href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/page_controller_addendum</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_199') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_200"
                                      tag="li">
                                    <a slot="link1" href="https://help.instagram.com/155833707900388" target="_blank" rel="nofollow">https://help.instagram.com/155833707900388</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_205') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_206"
                                      tag="li">
                                    <a slot="link1" href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/privacy-policy</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_207"
                                      tag="li">
                                    <a slot="link1" href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0" target="_blank" rel="nofollow">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_208"
                                      tag="li">
                                    <a slot="link1" href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/cookie-policy</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_196') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_197"
                                      tag="li">
                                    <a slot="link1" href="https://www.xing.com/privacy" target="_blank" rel="nofollow">https://www.xing.com/privacy</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_198"
                                      tag="li">
                                    <a slot="link1" href="https://privacy.xing.com/de/datenschutzerklaerung/druckversion" target="_blank" rel="nofollow">https://privacy.xing.com/de/datenschutzerklaerung/druckversion</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_192') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_193"
                                      tag="li">
                                    <a slot="link1" href="https://twitter.com/de/privacy" target="_blank" rel="nofollow">https://twitter.com/de/privacy</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_194"
                                      tag="li">
                                    <a slot="link1" href="https://twitter.com/personalization" target="_blank" rel="nofollow">https://twitter.com/personalization</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_195"
                                      tag="li">
                                    <a slot="link1" href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO" target="_blank" rel="nofollow">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_201') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_202"
                                      tag="li">
                                    <a slot="link1" href="https://policies.google.com/privacy" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_203"
                                      tag="li">
                                    <a slot="link1" href="https://adssettings.google.com/authenticated" target="_blank" rel="nofollow">https://adssettings.google.com/authenticated</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_204"
                                      tag="li">
                                    <a slot="link1" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" rel="nofollow">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_209') }}</strong></h4>
                            <ul>
                                <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_210"
                                      tag="li">
                                    <a slot="link1" href="https://www.tiktok.com/legal/privacy-policy?lang=de" target="_blank" rel="nofollow">https://www.tiktok.com/legal/privacy-policy?lang=de</a>
                                </i18n>
                                <i18n v-else path="kmso.privacy_policy.text_210"
                                      tag="li">
                                    <a slot="link1" href="https://www.tiktok.com/legal/privacy-policy?lang=en" target="_blank" rel="nofollow">https://www.tiktok.com/legal/privacy-policy?lang=en</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_211"
                                      tag="li">
                                    <a slot="link1" href="https://www.tiktok.com/legal/report/privacy" target="_blank" rel="nofollow">https://www.tiktok.com/legal/report/privacy</a>
                                </i18n>
                                <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_212"
                                      tag="li">
                                    <a slot="link1" href="https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=de" target="_blank" rel="nofollow">https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=de</a>
                                </i18n>
                                <i18n v-else path="kmso.privacy_policy.text_212"
                                      tag="li">
                                    <a slot="link1" href="https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=en" target="_blank" rel="nofollow">https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=en</a>
                                </i18n>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_213') }}</strong></h4>
                            <ul>
                                <i18n path="kmso.privacy_policy.text_214"
                                      tag="li">
                                    <a slot="link1" href="https://github.com/site/privacy" target="_blank" rel="nofollow">https://github.com/site/privacy</a>
                                </i18n>
                                <i18n path="kmso.privacy_policy.text_215"
                                      tag="li">
                                    <a slot="link1" href="https://www.privacyshield.gov/participant?id=a2zt000000001K2AAI" target="_blank" rel="nofollow">https://www.privacyshield.gov/participant?id=a2zt000000001K2AAI</a>
                                </i18n>
                            </ul>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_289') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_216') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_217') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_290') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_291') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_292') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_293') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_294') }}</li>
                            </ul>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_218') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_219') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_220') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_221') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_222') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_223') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_224') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_225') }}</li>
                            </ul>
                            <p>{{ $t('kmso.privacy_policy.text_226') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_227') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_228') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_229') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_230') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_231') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_232') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_233') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_234') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_235') }}</li>
                            </ul>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_236"
                                  tag="p">
                                <a slot="link1" href="https://kmso.de/impressum">Impressum</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_236"
                                  tag="p">
                                <a slot="link1" href="https://kmso.de/impressum">imprint</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_237') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_238') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_239') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <i18n path="kmso.privacy_policy.text_240"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com/business/a/page/page-insights" target="_blank" rel="nofollow">https://www.facebook.com/business/a/page/page-insights</a>
                            </i18n>
                            <i18n path="kmso.privacy_policy.text_241"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/page_controller_addendum</a>
                            </i18n>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_242') }}</strong></h4>
                            <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">{{ $t('kmso.privacy_policy.text_243') }}</p>
                            <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                {{ $t('kmso.privacy_policy.text_244') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_245') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_246') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_247') }}
                                <br>
                            </p>
                            <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">{{ $t('kmso.privacy_policy.text_248') }}</p>
                            <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                {{ $t('kmso.privacy_policy.text_249') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_250') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_251') }}
                                <br>
                                {{ $t('kmso.privacy_policy.text_252') }}
                            </p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_253') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_254') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_255') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_256') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_257') }}</li>
                            </ul>
                            <i18n path="kmso.privacy_policy.text_258"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com" target="_blank" rel="nofollow">https://www.facebook.com</a>
                            </i18n>
                            <h5 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_259') }}</strong></h5>
                            <i18n path="kmso.privacy_policy.text_260"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com/about/privacy/legal_bases/" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy/legal_bases/</a>
                                <a slot="link2" href="https://www.facebook.com/policies/cookies/" target="_blank" rel="nofollow">https://www.facebook.com/policies/cookies/</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_261') }}</p>
                            <h5 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_262') }}</strong></h5>
                            <p>{{ $t('kmso.privacy_policy.text_263') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_264') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_265') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_266') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_267') }}</li>
                            </ul>
                            <p>{{ $t('kmso.privacy_policy.text_268') }}</p>
                            <i18n path="kmso.privacy_policy.text_269"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com/policies/cookies/" target="_blank" rel="nofollow">https://www.facebook.com/policies/cookies/</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_270') }}</p>
                            <h5 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_271') }}</strong></h5>
                            <p>{{ $t('kmso.privacy_policy.text_272') }}</p>
                            <ul>
                                <li>{{ $t('kmso.privacy_policy.text_273') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_274') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_275') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_276') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_277') }}</li>
                                <li>{{ $t('kmso.privacy_policy.text_278') }}</li>
                            </ul>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_279"
                                  tag="p">
                                <a slot="link1" href="https://kmso.de/impressum">Impressum</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_279"
                                  tag="p">
                                <a slot="link1" href="https://kmso.de/impressum">imprint</a>
                            </i18n>
                            <p>{{ $t('kmso.privacy_policy.text_280') }}</p>
                            <i18n path="kmso.privacy_policy.text_281"
                                  tag="p">
                                <a slot="link1" href="https://www.facebook.com/policies/cookies/" target="_blank" rel="nofollow">https://www.facebook.com/policies/cookies/</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_282') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_283') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_284') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_285') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_286') }}</p>
                            <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_287"
                                  tag="p">
                                <a slot="link1" href="https://www.apple.com/de/legal/privacy/de-ww/" target="_blank" rel="nofollow">https://www.apple.com/de/legal/privacy/de-ww/</a>
                            </i18n>
                            <i18n v-else path="kmso.privacy_policy.text_287"
                                  tag="p">
                                <a slot="link1" href="https://www.apple.com/legal/privacy/en-ww/" target="_blank" rel="nofollow">https://www.apple.com/legal/privacy/en-ww/</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_295') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <i18n path="kmso.privacy_policy.text_296"
                                  tag="p">
                                <a slot="link1" href="https://boost-your-app.com" target="_blank" rel="nofollow">Boost Your App</a>
                            </i18n>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_297') }}</strong></h4>
                            <p>{{ $t('kmso.privacy_policy.text_298') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_306') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_300') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_299') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_307') }}</p>
                            <p>{{ $t('kmso.privacy_policy.text_305') }}</p>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_301') }}</strong></h4>
                            <i18n path="kmso.privacy_policy.text_302"
                                  tag="p">
                                <a slot="link1" href="mailto:boost-your-app@kmso.de" rel="nofollow">boost-your-app@kmso.de</a>
                            </i18n>
                            <h4 class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_303') }}</strong></h4>
                            <i18n path="kmso.privacy_policy.text_304"
                                  tag="p">
                                <a slot="link1" href="mailto:boost-your-app@kmso.de" rel="nofollow">boost-your-app@kmso.de</a>
                            </i18n>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_147') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                            <p>{{ $t('kmso.privacy_policy.text_148') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_149') }}</strong></h3>
                        <p>{{ $t('kmso.privacy_policy.text_150') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_151') }}</strong></h3>
                        <p>{{ $t('kmso.privacy_policy.text_152') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_153') }}</strong></h3>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-white-background">
                            <p>{{ $t('kmso.privacy_policy.text_154') }}</p>
                        </read-more>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_155') }}</strong></h3>
                        <p>{{ $t('kmso.privacy_policy.text_156') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="content is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"><strong>{{ $t('kmso.privacy_policy.text_157') }}</strong></h3>
                        <i18n v-if="this.$i18n.locale === 'de'" path="kmso.privacy_policy.text_158"
                              tag="p">
                            <a slot="link1" href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/" target="_blank" rel="nofollow">Datenschutzbeauftragter</a>
                            <b slot="link2"><a href="https://www.wbs-law.de/it-recht/datenschutzrecht/" target="_blank" rel="nofollow">Datenschutz Anwälten der Kanzlei WILDE BEUGER SOLMECKE | Rechtsanwälte</a></b>
                        </i18n>
                        <i18n v-else path="kmso.privacy_policy.text_158"
                              tag="p">
                            <a slot="link1" href="https://dg-datenschutz.de/services/external-data-protection-officer/?lang=en" target="_blank" rel="nofollow">DGD - Your External DPO</a>
                            <a slot="link2" href="https://www.wbs-law.de/it-recht/datenschutzrecht/" target="_blank" rel="nofollow"><b>German Lawyers from WILDE BEUGER SOLMECKE, Cologne</b></a>
                        </i18n>
                    </div>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="white-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';

    export default {
        name: "Datenschutz",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.footer.data_protection"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
