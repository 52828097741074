<template>
    <section>
        <div class="columns">
            <div class="column is-offset-one-quarter is-half">
                <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                        <input class="input" :class="{'is-danger': !nameValid}" type="text" v-model="name">
                    </div>
                </div>

                <div class="field">
                    <label class="label">E-Mail</label>
                    <div class="control">
                        <input class="input" :class="{'is-danger': !emailValid}" type="text" v-model="email">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Passwort</label>
                    <div class="control">
                        <input class="input" :class="{'is-danger': !passwordValid}" type="password" v-model="password">
                    </div>
                </div>

                <button class="button is-primary" v-on:click="createNewUser">Anlegen</button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "NewUser",
        data() {
            return {
                name: null,
                email: null,
                password: null,
                nameValid: true,
                emailValid: true,
                passwordValid: true,
            }
        },
        methods: {
            createNewUser() {
                this.nameValid = true;
                this.emailValid = true;
                this.passwordValid = true;

                console.log(this.name, this.email, this.password);
                this.$auth.register({
                    data: {
                        name: this.name,
                        email: this.email,
                        password: this.password
                    },
                    success: response => {
                        console.log(response.data);

                        if(response.data.status === 'success') {
                            window.location = '/backend/new-user';
                        }
                    },
                    error: response => {
                        if(response.response.data.errors) {
                            let errors = response.response.data.errors;

                            if(errors.name) {
                                this.nameValid = false;
                            }

                            if(errors.email) {
                                this.emailValid = false;
                            }

                            if(errors.password) {
                                this.passwordValid = false;
                            }
                        }
                        console.log('error', response.response.data.errors);

                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
