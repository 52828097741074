<template>
    <div>
        <div v-if="this.isMobile && !this.isTablet">
            <div ref="textContainer" class="text-transition-down text-content">
                <slot></slot>
            </div>

            <div v-if="!this.collapsed">
                <div class="pointer-hand" @click="this.collapse">
                    <div class="shadow-up" :class="this.backgroundShadow"></div>
                    <div class="text-below-shadow-up text-blue">
                        <p class="is-size-5-custom">Mehr lesen</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Read-More",
        props: [
            'isMobile',
            'isTablet',
            'backgroundShadow',
        ],
        data() {
            return {
                collapsed: null,
            };
        },
        methods: {
            collapse() {
                if (!this.collapsed) {
                    this.collapsed = true;
                }
            }
        },
        updated() {
            if (this.collapsed) {
                this.$refs.textContainer.style.setProperty('max-height', this.$refs.textContainer.scrollHeight + 'px');
                this.collapsed = true;
            }
        },
    }
</script>

<style scoped>
    .text-transition-down {
        transition: max-height .3s ease;
    }
    .text-content {
        max-height: 9.5em;
        overflow: hidden;
    }

    .shadow-up {
        height: 3em;
        transform: translateY(-3em);
    }

    .text-below-shadow-up {
        margin-top: -3em;
    }
</style>
