<template>
    <router-view :is-mobile="this.isMobile"
                 :theme="this.theme" />
</template>

<script>
    export default {
        name: "PageEmpty",
        props: [
            'isMobile',
            'theme',
        ],
    }
</script>

<style scoped>

</style>
