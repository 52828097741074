<template>
    <figure class="image is-1by1" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
        <div onclick="window.open('https://www.linkedin.com/company/kmso-it-dienstleistungen-gmbh/', '_blank')" class="pointer-hand">
            <img src="/img/social/linkedin.png" alt="LinkedIn" :class="{ 'blog-social-icons-mobile': this.isMobile }">
        </div>
    </figure>
</template>

<script>
    export default {
        name: 'Blog-3',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
