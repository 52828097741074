<template>
    <div>
        <router-link :to="{ hash: '#desktopanwendungen' }" @click.native="$moveToAnchor('#desktopanwendungen')">
            <article class="message" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                <div class="message-header message-header-color-6 is-size-5-custom message-header-center has-text-centered" :class="{ 'min-text-height-message': !this.isTablet, 'min-text-height-message-custom-tablet': this.isTablet }">
                    <p>
                        {{ $t('kmso.references.main_box_3_title') }}
                    </p>
                </div>
                <div class="message-body is-size-5-custom">
                    <figure class="image is-3by2">
                        <img src="/img/desktop.svg" :alt="$t('kmso.references.main_box_3_title')">
                    </figure>
                </div>
                <div class="message-footer message-header-color-6 is-size-5-custom">
                    <div class="is-vertical-center add-horizontal-center message-min-text-footer-height">
                        <p class="link-text">
                            {{ $t('kmso.references.main_show_projects') }}
                        </p>
                    </div>
                </div>
            </article>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Referenzen-Software-3',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
