<template>
    <section>
        <div class="editor">
            <br />
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs, menu }" @hide="hideLinkMenu">
                <div class="menubar">
                    <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                        <input type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                        <button @click="setLinkUrl(commands.link, null)" type="button">
                            Delete
                        </button>
                    </form>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.link() }"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                    >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.blockquote() }"
                        @click="commands.blockquote"
                    >
                        BlockQuote
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                    >
                        Bold
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                    >
                        Italic
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                        @click="commands.heading({ level: 5 })"
                    >
                        H5
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                        @click="commands.heading({ level: 6 })"
                    >
                        H6
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.code() }"
                        @click="commands.code"
                    >
                        Code
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.code_block() }"
                        @click="commands.code_block"
                    >
                        CodeBlock
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.ordered_list() }"
                        @click="commands.ordered_list"
                    >
                        OrderedList
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        :class="{ 'is-active': isActive.bullet_list() }"
                        @click="commands.bullet_list"
                    >
                        BulletList
                    </button>

                    <button
                        v-if="!isPreview"
                        class="button"
                        @click="openModal(commands.image)"
                    >Image</button>
                </div>
            </editor-menu-bar>
            <div class="box">
                <editor-content class="content" :editor="editor" />
            </div>
        </div>

        <div class="modal" :class="{'is-active': showModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Bild einfügen</p>
                    <button class="delete" aria-label="close" v-on:click="closeModal" />
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <h5>Bild hochladen</h5>

                        <p>
                            <vue-dropzone
                                ref="imageUploadDropzone"
                                id="dropzone"
                                :options="dropzoneOptions"
                                v-on:vdropzone-success="imageUploadSuccess"
                                v-on:vdropzone-error="imageUploadError"
                            />
                        </p>

                        <h5>URL eingeben</h5>

                        <p>
                            <input class="input" type="text" v-model="imageUrl">
                        </p>

                        <p>Vorschau</p>

                        <p>
                            <img alt="Keine Vorschau verfügbar" :src="imageUrl">
                        </p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" v-on:click="insertImage">Bild einfügen</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        Bold,
        Italic,
        Heading,
        CodeBlock,
        Code,
        ListItem,
        OrderedList,
        BulletList,
        Image,
        Link,
    } from 'tiptap-extensions'

    export default {
        name: "NewEntry",
        props: [
            'postId',
            'language',
            'isPreview'
        ],
        components: {
            EditorContent,
            EditorMenuBar,
            vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                editor: null,
                imageUploadModal: null,
                showModal: false,
                imageUrl: null,
                currentCommand: null,
                dropzoneOptions: {
                    url: '/api/v1/backend/blog/image-upload',
                    thumbnailWidth: 150,
                    maxFilesize: 2,
                    headers: { "Authorization": "Bearer " + this.$auth.token() },
                    maxFiles: 1,
                    acceptedFiles: 'image/jpeg, image/gif, image/png',
                },
                linkUrl: null,
                linkMenuIsActive: false,
            }
        },
        mounted() {
            let editorContent = '<p>Verfasse einen neuen Blogeintrag!</p><p></p>';

            if(this.isPreview) {
                editorContent = '<p>Verfasse einen Text für die Vorschau!</p><p></p>';
            }

            this.editor = new Editor({
                extensions: [
                    new Blockquote(),
                    new Bold(),
                    new Italic(),
                    new Heading(),
                    new CodeBlock(),
                    new Code(),
                    new ListItem(),
                    new OrderedList(),
                    new BulletList(),
                    new Image,
                    new Link(),
                ],
                content: editorContent,
            })
        },
        methods: {
            openImageUpload(command) {
                this.openModal(command);
            },
            closeModal() {
                this.showModal = false;
                this.currentCommand = null;
            },
            openModal(command) {
                this.currentCommand = command;
                this.showModal = true;
            },
            getHtml() {
                let text = this.editor.getHTML();

                if(text === '<p></p>') {
                    text = null;
                }

                return text;
            },
            getJson() {
                return this.editor.getJSON();
            },
            setContent(text) {
                this.editor.setContent(text);
            },
            insertImage() {
                if(this.currentCommand !== null) {
                    const imageUrlLocal = this.imageUrl;
                    this.currentCommand({ src: imageUrlLocal });
                    this.showModal = false;
                    this.imageUrl = null;
                }
            },
            imageUploadSuccess(file, response) {
                this.imageUrl = response.url;
                this.$refs.imageUploadDropzone.removeAllFiles(true);
            },
            imageUploadError(file, message, xhr) {
                console.log('error', file, message, xhr);
            },
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href
                this.linkMenuIsActive = true
                this.$nextTick(() => {
                    this.$refs.linkInput.focus()
                })
            },
            hideLinkMenu() {
                this.linkUrl = null
                this.linkMenuIsActive = false
            },
            setLinkUrl(command, url) {
                command({ href: url })
                this.hideLinkMenu()
            },
        },
        beforeDestroy() {
            this.editor.destroy()
        },
    }
</script>

<style scoped>

</style>
