<template>
    <figure class="image is-1by1" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
        <div onclick="window.open('https://github.com/kmso-de', '_blank')" class="pointer-hand">
            <img v-if="this.$theme === this.themes[0]" src="/img/social/github_light.png" alt="GitHub" :class="{ 'blog-social-icons-mobile': this.isMobile }">
            <img v-else src="/img/social/github.png" alt="GitHub" :class="{ 'blog-social-icons-mobile': this.isMobile }">
        </div>
    </figure>
</template>

<script>
    export default {
        name: 'Blog-6',
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                themes: ['dark', 'light'],
            }
        }
    }
</script>
