<template>
    <section>
        <div class="columns">
            <div class="column is-2 menu-settings" v-if="$auth.check()">
                <backend-menu />
            </div>

            <div class="column">
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
    import BackendMenu from "../../components/backend/BackendMenu";

    export default {
        components: {
            BackendMenu,
        },
        name: "BackendView",
    }
</script>

<style scoped>
    .menu-settings {
        background-color: #00917c;
        position: sticky;
        top: 0;
    }

    @media screen and (min-width: 768px) {
        .menu-settings {
            height: 100vh;
        }
    }
</style>
