import { render, staticRenderFns } from "./BackendView.vue?vue&type=template&id=2d054826&scoped=true&"
import script from "./BackendView.vue?vue&type=script&lang=js&"
export * from "./BackendView.vue?vue&type=script&lang=js&"
import style0 from "./BackendView.vue?vue&type=style&index=0&id=2d054826&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d054826",
  null
  
)

export default component.exports