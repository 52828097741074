<template>
    <div>
        <div class="circle-wrapper circle-wrapper-color" data-aos="circle-progress-animate-2">
            <svg viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" class="circle-progress-wrapper">
                <circle cx="75" cy="75" r="67.5" class="circle-progress" stroke="#e59b30" ></circle>
            </svg>
            <div class="inside-circle inside-circle-color has-black-text">
                <div class="display-flex inside-circle-numbers inside-circle-numbers-animate-2">
                    <div v-for="(n, i) in 28">
                        {{ i }}%
                    </div>
                </div>
            </div>
        </div>
        <article class="message circle-box-margin-top">
            <div class="message-body message-border-color-4">
                <p class="is-size-5-custom is-vertical-center has-text-centered add-horizontal-center"
                   :class="{ 'min-text-height box-margin-top': !this.isMobile, 'min-text-height-mobile box-margin-top-mobile': this.isMobile }">
                    {{ $t('kmso.home.project_management_fact_2') }}
                </p>
                <br>
                <div class="has-text-centered" :class="{ 'min-text-footer-height': !this.isMobile }">
                    <div onclick="window.open('https://hbr.org/2011/09/why-your-it-project-may-be-riskier-than-you-think', '_blank')" class="pointer-hand text-blue">
                        Harvard Business Review
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Startseite-Projektmanagement-Circle-1',
        props: [
            'isMobile',
        ],
    }
</script>
