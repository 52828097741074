<template>
    <div>
        <!-- Mobile -->
        <div v-if="this.isMobile">
            <VueSlickCarousel v-if="this.carousel === this.carousels[0]" :arrows="false" :dots="true">
                <StartseiteProjektmanagementCircle1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagementCircle1>
                <StartseiteProjektmanagementCircle2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagementCircle2>
                <StartseiteProjektmanagementCircle3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagementCircle3>
                <StartseiteProjektmanagementCircle4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagementCircle4>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[1]" :arrows="false" :dots="true">
                <StartseiteSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteSoftware1>
                <StartseiteSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteSoftware2>
                <StartseiteSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteSoftware3>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[2]" :arrows="false" :dots="true" class="carousel-margin-bottom">
                <LeistungenProjektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement1>
                <LeistungenProjektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement2>
                <LeistungenProjektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement3>
                <LeistungenProjektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement4>
                <LeistungenProjektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement5>
                <LeistungenProjektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenProjektmanagement6>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[3]" :arrows="false" :dots="true" class="carousel-margin-bottom">
                <LeistungenSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenSoftware1>
                <LeistungenSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenSoftware2>
                <LeistungenSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></LeistungenSoftware3>
            </VueSlickCarousel>
            <!-- 4 (contact-box) is missing in mobile -->
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[5]" :arrows="false" :dots="true">
                <ReferenzenSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenSoftware1>
                <ReferenzenSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenSoftware2>
                <ReferenzenSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenSoftware3>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[6]" :arrows="false" :dots="true">
                <Projektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement1>
                <Projektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement2>
                <Projektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement3>
                <Projektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement4>
                <Projektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement5>
                <Projektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Projektmanagement6>
            </VueSlickCarousel>
            <!-- 7 is missing because projectmanagement is in mobile 1 row and in web 2 -->
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[8]" :arrows="false" :dots="true">
                <Software1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Software1>
                <Software2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Software2>
                <Software3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Software3>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[9]" :arrows="false" :dots="true">
                <ProjektmanagementFazit11 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit11>
                <ProjektmanagementFazit12 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit12>
                <ProjektmanagementFazit13 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit13>
                <ProjektmanagementFazit14 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit14>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[10]" :arrows="false" :dots="true">
                <ProjektmanagementFazit21 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit21>
                <ProjektmanagementFazit22 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit22>
                <ProjektmanagementFazit23 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit23>
                <ProjektmanagementFazit24 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit24>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[11]" :arrows="false" :dots="true">
                <ProjektmanagementFazit31 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit31>
                <ProjektmanagementFazit32 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit32>
                <ProjektmanagementFazit33 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit33>
                <ProjektmanagementFazit34 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit34>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[12]" :arrows="false" :dots="true">
                <ProjektmanagementFazit41 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit41>
                <ProjektmanagementFazit42 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit42>
                <ProjektmanagementFazit43 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit43>
                <ProjektmanagementFazit44 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit44>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[13]" :arrows="false" :dots="true">
                <ProjektmanagementFazit51 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit51>
                <ProjektmanagementFazit52 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit52>
                <ProjektmanagementFazit53 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit53>
                <ProjektmanagementFazit54 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit54>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[14]" :arrows="false" :dots="true">
                <ProjektmanagementFazit61 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit61>
                <ProjektmanagementFazit62 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit62>
                <ProjektmanagementFazit63 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit63>
                <ProjektmanagementFazit64 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ProjektmanagementFazit64>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[15]" :arrows="false" :dots="true">
                <SoftwareFazit11 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit11>
                <SoftwareFazit12 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit12>
                <SoftwareFazit13 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit13>
                <SoftwareFazit14 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit14>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[16]" :arrows="false" :dots="true">
                <SoftwareFazit21 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit21>
                <SoftwareFazit22 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit22>
                <SoftwareFazit23 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit23>
                <SoftwareFazit24 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit24>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[17]" :arrows="false" :dots="true">
                <SoftwareFazit31 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit31>
                <SoftwareFazit32 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit32>
                <SoftwareFazit33 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit33>
                <SoftwareFazit34 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareFazit34>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[18]" :arrows="false" :dots="true">
                <Blog1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog1>
                <Blog2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog2>
                <Blog3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog3>
                <Blog4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog4>
                <Blog5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog5>
                <Blog6 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></Blog6>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[19]" :arrows="false" :dots="true">
                <ReferenzenKunden5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKunden5>
                <ReferenzenKunden1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKunden1>
                <ReferenzenKunden2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKunden2>
                <ReferenzenKunden3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKunden3>
                <ReferenzenKunden4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKunden4>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[20]" :arrows="false" :dots="true">
                <ReferenzenKundenZitate1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate1>
                <ReferenzenKundenZitate2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate2>
                <ReferenzenKundenZitate3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate3>
            </VueSlickCarousel>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[21]" :arrows="false" :dots="true" class="carousel-margin-bottom">
                <StartseiteProjektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement1>
                <StartseiteProjektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement2>
                <StartseiteProjektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement3>
                <StartseiteProjektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement4>
                <StartseiteProjektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement5>
                <StartseiteProjektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></StartseiteProjektmanagement6>
            </VueSlickCarousel>
            <!-- 22 is missing because startseite-projektmanagement is in mobile 1 row and in web 2 -->
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[23]" :arrows="false" :dots="true">
                <SoftwareTechnologien1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien1>
                <SoftwareTechnologien2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien2>
                <SoftwareTechnologien3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien3>
                <SoftwareTechnologien4 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien4>
                <SoftwareTechnologien5 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien5>
                <SoftwareTechnologien6 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien6>
                <SoftwareTechnologien7 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien7>
                <SoftwareTechnologien8 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien8>
                <SoftwareTechnologien9 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></SoftwareTechnologien9>
            </VueSlickCarousel>
            <!-- 24 is missing because software-technologien is in mobile 1 row and in web 3 -->
            <!-- 25 is missing because software-technologien is in mobile 1 row and in web 3 -->
        </div>
        <!-- Web -->
        <div v-else>
            <div v-if="this.carousel === this.carousels[0]" class="columns">
                <div class="column is-3">
                    <StartseiteProjektmanagementCircle1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagementCircle1>
                </div>
                <div class="column is-3">
                    <StartseiteProjektmanagementCircle2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagementCircle2>
                </div>
                <div class="column is-3">
                    <StartseiteProjektmanagementCircle3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagementCircle3>
                </div>
                <div class="column is-3">
                    <StartseiteProjektmanagementCircle4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagementCircle4>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[1]" class="columns">
                <div class="column is-4">
                    <StartseiteSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteSoftware1>
                </div>
                <div class="column is-4">
                    <StartseiteSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteSoftware2>
                </div>
                <div class="column is-4">
                    <StartseiteSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteSoftware3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[2]">
                <LeistungenProjektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement1>
                <br>
                <LeistungenProjektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement2>
                <br>
                <LeistungenProjektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement3>
                <br>
                <LeistungenProjektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement4>
                <br>
                <LeistungenProjektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement5>
                <br>
                <LeistungenProjektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenProjektmanagement6>
            </div>
            <div v-else-if="this.carousel === this.carousels[3]">
                <LeistungenSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenSoftware1>
                <br>
                <LeistungenSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenSoftware2>
                <br>
                <LeistungenSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></LeistungenSoftware3>
            </div>
            <!-- 4 (contact-box) is missing in desktop -->
            <div v-else-if="this.carousel === this.carousels[5]" class="columns">
                <div class="column is-4">
                    <ReferenzenSoftware1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenSoftware1>
                </div>
                <div class="column is-4">
                    <ReferenzenSoftware2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenSoftware2>
                </div>
                <div class="column is-4">
                    <ReferenzenSoftware3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenSoftware3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[6]" class="columns">
                <div class="column is-4">
                    <Projektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement1>
                </div>
                <div class="column is-4">
                    <Projektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement2>
                </div>
                <div class="column is-4">
                    <Projektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[7]" class="columns">
                <div class="column is-4">
                    <Projektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement4>
                </div>
                <div class="column is-4">
                    <Projektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement5>
                </div>
                <div class="column is-4">
                    <Projektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Projektmanagement6>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[8]" class="columns">
                <div class="column is-4">
                    <Software1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Software1>
                </div>
                <div class="column is-4">
                    <Software2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Software2>
                </div>
                <div class="column is-4">
                    <Software3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Software3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[9]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit11 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit11>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit12 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit12>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit13 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit13>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit14 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit14>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[10]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit21 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit21>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit22 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit22>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit23 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit23>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit24 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit24>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[11]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit31 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit31>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit32 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit32>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit33 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit33>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit34 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit34>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[12]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit41 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit41>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit42 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit42>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit43 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit43>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit44 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit44>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[13]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit51 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit51>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit52 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit52>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit53 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit53>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit54 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit54>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[14]" class="columns">
                <div class="column is-3">
                    <ProjektmanagementFazit61 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit61>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit62 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit62>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit63 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit63>
                </div>
                <div class="column is-3">
                    <ProjektmanagementFazit64 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ProjektmanagementFazit64>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[15]" class="columns">
                <div class="column is-3">
                    <SoftwareFazit11 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit11>
                </div>
                <div class="column is-3">
                    <SoftwareFazit12 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit12>
                </div>
                <div class="column is-3">
                    <SoftwareFazit13 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit13>
                </div>
                <div class="column is-3">
                    <SoftwareFazit14 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit14>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[16]" class="columns">
                <div class="column is-3">
                    <SoftwareFazit21 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit21>
                </div>
                <div class="column is-3">
                    <SoftwareFazit22 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit22>
                </div>
                <div class="column is-3">
                    <SoftwareFazit23 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit23>
                </div>
                <div class="column is-3">
                    <SoftwareFazit24 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit24>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[17]" class="columns">
                <div class="column is-3">
                    <SoftwareFazit31 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit31>
                </div>
                <div class="column is-3">
                    <SoftwareFazit32 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit32>
                </div>
                <div class="column is-3">
                    <SoftwareFazit33 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit33>
                </div>
                <div class="column is-3">
                    <SoftwareFazit34 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareFazit34>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[18]" class="columns is-variable is-8">
                <div class="column is-2">
                    <Blog1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog1>
                </div>
                <div class="column is-2">
                    <Blog2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog2>
                </div>
                <div class="column is-2">
                    <Blog3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog3>
                </div>
                <div class="column is-2">
                    <Blog4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog4>
                </div>
                <div class="column is-2">
                    <Blog5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog5>
                </div>
                <div class="column is-2">
                    <Blog6 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></Blog6>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[19]" class="columns is-centered">
                <div class="column is-2">
                    <ReferenzenKunden5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenKunden5>
                </div>
                <div class="column is-2">
                    <ReferenzenKunden1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenKunden1>
                </div>
                <div class="column is-2">
                    <ReferenzenKunden2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenKunden2>
                </div>
                <div class="column is-2">
                    <ReferenzenKunden3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenKunden3>
                </div>
                <div class="column is-2">
                    <ReferenzenKunden4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></ReferenzenKunden4>
                </div>
            </div>
            <VueSlickCarousel v-else-if="this.carousel === this.carousels[20]" :arrows="false" :dots="true">
                <ReferenzenKundenZitate1 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate1>
                <ReferenzenKundenZitate2 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate2>
                <ReferenzenKundenZitate3 :is-mobile="this.isMobile" :is-tablet="this.isTablet" class="carousel-margin"></ReferenzenKundenZitate3>
            </VueSlickCarousel>
            <div v-else-if="this.carousel === this.carousels[21]" class="columns">
                <div class="column is-4">
                    <StartseiteProjektmanagement1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement1>
                </div>
                <div class="column is-4">
                    <StartseiteProjektmanagement2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement2>
                </div>
                <div class="column is-4">
                    <StartseiteProjektmanagement3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[22]" class="columns">
                <div class="column is-4">
                    <StartseiteProjektmanagement4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement4>
                </div>
                <div class="column is-4">
                    <StartseiteProjektmanagement5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement5>
                </div>
                <div class="column is-4">
                    <StartseiteProjektmanagement6 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></StartseiteProjektmanagement6>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[23]" class="columns">
                <div class="column is-4">
                    <SoftwareTechnologien1 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien1>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien2 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien2>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien3 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien3>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[24]" class="columns">
                <div class="column is-4">
                    <SoftwareTechnologien4 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien4>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien5 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien5>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien6 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien6>
                </div>
            </div>
            <div v-else-if="this.carousel === this.carousels[25]" class="columns">
                <div class="column is-4">
                    <SoftwareTechnologien7 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien7>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien8 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien8>
                </div>
                <div class="column is-4">
                    <SoftwareTechnologien9 :is-mobile="this.isMobile" :is-tablet="this.isTablet"></SoftwareTechnologien9>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    import VueSlickCarousel from 'vue-slick-carousel';

    import StartseiteProjektmanagementCircle1 from '../components/startseite-projektmanagement-circle-1';
    import StartseiteProjektmanagementCircle2 from '../components/startseite-projektmanagement-circle-2';
    import StartseiteProjektmanagementCircle3 from '../components/startseite-projektmanagement-circle-3';
    import StartseiteProjektmanagementCircle4 from '../components/startseite-projektmanagement-circle-4';

    import StartseiteSoftware1 from '../components/startseite-software-1';
    import StartseiteSoftware2 from '../components/startseite-software-2';
    import StartseiteSoftware3 from '../components/startseite-software-3';

    import LeistungenProjektmanagement1 from '../components/leistungen-projektmanagement-1';
    import LeistungenProjektmanagement2 from '../components/leistungen-projektmanagement-2';
    import LeistungenProjektmanagement3 from '../components/leistungen-projektmanagement-3';
    import LeistungenProjektmanagement4 from '../components/leistungen-projektmanagement-4';
    import LeistungenProjektmanagement5 from '../components/leistungen-projektmanagement-5';
    import LeistungenProjektmanagement6 from '../components/leistungen-projektmanagement-6';

    import LeistungenSoftware1 from "../components/leistungen-software-1";
    import LeistungenSoftware2 from "../components/leistungen-software-2";
    import LeistungenSoftware3 from "../components/leistungen-software-3";

    import ReferenzenSoftware1 from '../components/referenzen-software-1';
    import ReferenzenSoftware2 from '../components/referenzen-software-2';
    import ReferenzenSoftware3 from '../components/referenzen-software-3';

    import Projektmanagement1 from "../components/projektmanagement-1";
    import Projektmanagement2 from "../components/projektmanagement-2";
    import Projektmanagement3 from "../components/projektmanagement-3";
    import Projektmanagement4 from "../components/projektmanagement-4";
    import Projektmanagement5 from "../components/projektmanagement-5";
    import Projektmanagement6 from "../components/projektmanagement-6";

    import Software1 from "../components/software-1";
    import Software2 from "../components/software-2";
    import Software3 from "../components/software-3";

    import ProjektmanagementFazit11 from "../components/fazit/projektmanagement-1-1";
    import ProjektmanagementFazit12 from "../components/fazit/projektmanagement-1-2";
    import ProjektmanagementFazit13 from "../components/fazit/projektmanagement-1-3";
    import ProjektmanagementFazit14 from "../components/fazit/projektmanagement-1-4";
    import ProjektmanagementFazit21 from "../components/fazit/projektmanagement-2-1";
    import ProjektmanagementFazit22 from "../components/fazit/projektmanagement-2-2";
    import ProjektmanagementFazit23 from "../components/fazit/projektmanagement-2-3";
    import ProjektmanagementFazit24 from "../components/fazit/projektmanagement-2-4";
    import ProjektmanagementFazit31 from "../components/fazit/projektmanagement-3-1";
    import ProjektmanagementFazit32 from "../components/fazit/projektmanagement-3-2";
    import ProjektmanagementFazit33 from "../components/fazit/projektmanagement-3-3";
    import ProjektmanagementFazit34 from "../components/fazit/projektmanagement-3-4";
    import ProjektmanagementFazit41 from "../components/fazit/projektmanagement-4-1";
    import ProjektmanagementFazit42 from "../components/fazit/projektmanagement-4-2";
    import ProjektmanagementFazit43 from "../components/fazit/projektmanagement-4-3";
    import ProjektmanagementFazit44 from "../components/fazit/projektmanagement-4-4";
    import ProjektmanagementFazit51 from "../components/fazit/projektmanagement-5-1";
    import ProjektmanagementFazit52 from "../components/fazit/projektmanagement-5-2";
    import ProjektmanagementFazit53 from "../components/fazit/projektmanagement-5-3";
    import ProjektmanagementFazit54 from "../components/fazit/projektmanagement-5-4";
    import ProjektmanagementFazit61 from "../components/fazit/projektmanagement-6-1";
    import ProjektmanagementFazit62 from "../components/fazit/projektmanagement-6-2";
    import ProjektmanagementFazit63 from "../components/fazit/projektmanagement-6-3";
    import ProjektmanagementFazit64 from "../components/fazit/projektmanagement-6-4";

    import SoftwareFazit11 from "../components/fazit/software-1-1";
    import SoftwareFazit12 from "../components/fazit/software-1-2";
    import SoftwareFazit13 from "../components/fazit/software-1-3";
    import SoftwareFazit14 from "../components/fazit/software-1-4";
    import SoftwareFazit21 from "../components/fazit/software-2-1";
    import SoftwareFazit22 from "../components/fazit/software-2-2";
    import SoftwareFazit23 from "../components/fazit/software-2-3";
    import SoftwareFazit24 from "../components/fazit/software-2-4";
    import SoftwareFazit31 from "../components/fazit/software-3-1";
    import SoftwareFazit32 from "../components/fazit/software-3-2";
    import SoftwareFazit33 from "../components/fazit/software-3-3";
    import SoftwareFazit34 from "../components/fazit/software-3-4";

    import Blog1 from "../components/blog-1";
    import Blog2 from "../components/blog-2";
    import Blog3 from "../components/blog-3";
    import Blog4 from "../components/blog-4";
    import Blog5 from "../components/blog-5";
    import Blog6 from "../components/blog-6";

    import ReferenzenKunden1 from "../components/referenzen-kunden-1";
    import ReferenzenKunden2 from "../components/referenzen-kunden-2";
    import ReferenzenKunden3 from "../components/referenzen-kunden-3";
    import ReferenzenKunden4 from "../components/referenzen-kunden-4";
    import ReferenzenKunden5 from "../components/referenzen-kunden-5";

    import ReferenzenKundenZitate1 from "../components/referenzen-kunden-zitate-1";
    import ReferenzenKundenZitate2 from "../components/referenzen-kunden-zitate-2";
    import ReferenzenKundenZitate3 from "../components/referenzen-kunden-zitate-3";

    import StartseiteProjektmanagement1 from '../components/startseite-projektmanagement-1';
    import StartseiteProjektmanagement2 from '../components/startseite-projektmanagement-2';
    import StartseiteProjektmanagement3 from '../components/startseite-projektmanagement-3';
    import StartseiteProjektmanagement4 from '../components/startseite-projektmanagement-4';
    import StartseiteProjektmanagement5 from '../components/startseite-projektmanagement-5';
    import StartseiteProjektmanagement6 from '../components/startseite-projektmanagement-6';

    import SoftwareTechnologien1 from '../components/software-technologien-1';
    import SoftwareTechnologien2 from '../components/software-technologien-2';
    import SoftwareTechnologien3 from '../components/software-technologien-3';
    import SoftwareTechnologien4 from '../components/software-technologien-4';
    import SoftwareTechnologien5 from '../components/software-technologien-5';
    import SoftwareTechnologien6 from '../components/software-technologien-6';
    import SoftwareTechnologien7 from '../components/software-technologien-7';
    import SoftwareTechnologien8 from '../components/software-technologien-8';
    import SoftwareTechnologien9 from '../components/software-technologien-9';

    export default {
        name: 'Carousel',
        props: [
            'isMobile',
            'carousel',
            'isTablet',
        ],
        data() {
            return {
                carousels: [
                    'Startseite-Projektmanagement-Circle',
                    'Startseite-Software',
                    'Leistungen-Projektmanagement',
                    'Leistungen-Software',
                    'Contact-Box',
                    'Referenzen-Software',
                    'Projektmanagement-1',
                    'Projektmanagement-2',
                    'Software',
                    'Projektmanagement-Fazit-1',
                    'Projektmanagement-Fazit-2',
                    'Projektmanagement-Fazit-3',
                    'Projektmanagement-Fazit-4',
                    'Projektmanagement-Fazit-5',
                    'Projektmanagement-Fazit-6',
                    'Software-Fazit-1',
                    'Software-Fazit-2',
                    'Software-Fazit-3',
                    'Blog',
                    'Referenzen-Kunden',
                    'Referenzen-Kunden-Zitate',
                    'Startseite-Projektmanagement-1',
                    'Startseite-Projektmanagement-2',
                    'Software-Technologien-1',
                    'Software-Technologien-2',
                    'Software-Technologien-3',
                ],
            }
        },
        components: {
            VueSlickCarousel,
            StartseiteProjektmanagementCircle1,
            StartseiteProjektmanagementCircle2,
            StartseiteProjektmanagementCircle3,
            StartseiteProjektmanagementCircle4,
            StartseiteSoftware1,
            StartseiteSoftware2,
            StartseiteSoftware3,
            LeistungenProjektmanagement1,
            LeistungenProjektmanagement2,
            LeistungenProjektmanagement3,
            LeistungenProjektmanagement4,
            LeistungenProjektmanagement5,
            LeistungenProjektmanagement6,
            LeistungenSoftware1,
            LeistungenSoftware2,
            LeistungenSoftware3,
            ReferenzenSoftware1,
            ReferenzenSoftware2,
            ReferenzenSoftware3,
            Projektmanagement1,
            Projektmanagement2,
            Projektmanagement3,
            Projektmanagement4,
            Projektmanagement5,
            Projektmanagement6,
            Software1,
            Software2,
            Software3,
            ProjektmanagementFazit11,
            ProjektmanagementFazit12,
            ProjektmanagementFazit13,
            ProjektmanagementFazit14,
            ProjektmanagementFazit21,
            ProjektmanagementFazit22,
            ProjektmanagementFazit23,
            ProjektmanagementFazit24,
            ProjektmanagementFazit31,
            ProjektmanagementFazit32,
            ProjektmanagementFazit33,
            ProjektmanagementFazit34,
            ProjektmanagementFazit41,
            ProjektmanagementFazit42,
            ProjektmanagementFazit43,
            ProjektmanagementFazit44,
            ProjektmanagementFazit51,
            ProjektmanagementFazit52,
            ProjektmanagementFazit53,
            ProjektmanagementFazit54,
            ProjektmanagementFazit61,
            ProjektmanagementFazit62,
            ProjektmanagementFazit63,
            ProjektmanagementFazit64,
            SoftwareFazit11,
            SoftwareFazit12,
            SoftwareFazit13,
            SoftwareFazit14,
            SoftwareFazit21,
            SoftwareFazit22,
            SoftwareFazit23,
            SoftwareFazit24,
            SoftwareFazit31,
            SoftwareFazit32,
            SoftwareFazit33,
            SoftwareFazit34,
            Blog1,
            Blog2,
            Blog3,
            Blog4,
            Blog5,
            Blog6,
            ReferenzenKunden1,
            ReferenzenKunden2,
            ReferenzenKunden3,
            ReferenzenKunden4,
            ReferenzenKunden5,
            ReferenzenKundenZitate1,
            ReferenzenKundenZitate2,
            ReferenzenKundenZitate3,
            StartseiteProjektmanagement1,
            StartseiteProjektmanagement2,
            StartseiteProjektmanagement3,
            StartseiteProjektmanagement4,
            StartseiteProjektmanagement5,
            StartseiteProjektmanagement6,
            SoftwareTechnologien1,
            SoftwareTechnologien2,
            SoftwareTechnologien3,
            SoftwareTechnologien4,
            SoftwareTechnologien5,
            SoftwareTechnologien6,
            SoftwareTechnologien7,
            SoftwareTechnologien8,
            SoftwareTechnologien9,
        },
    }
</script>
