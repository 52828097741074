<template>
    <div>
        <article class="message">
            <div class="message-body message-border-color-6">
                <p class="is-size-5-custom is-vertical-center min-text-height"
                   :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered add-horizontal-center': !this.isMobile || this.isTablet }">
                    {{ $t('kmso.project_management.business_summary_3_text') }}
                </p>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Projektmanagement-Fazit-6-3',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
