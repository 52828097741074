<template>
    <div>
        <div class="circle-wrapper circle-wrapper-color" data-aos="circle-progress-animate-4">
            <svg viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" class="circle-progress-wrapper">
                <circle cx="75" cy="75" r="67.5" class="circle-progress" stroke="#009e86" ></circle>
            </svg>
            <div class="inside-circle inside-circle-color has-black-text">
                <div class="display-flex inside-circle-numbers inside-circle-numbers-animate-4">
                    <div v-for="(n, i) in 72">
                        {{ i }}%
                    </div>
                </div>
            </div>
        </div>
        <article class="message circle-box-margin-top">
            <div class="message-body message-border-color-3">
                <p class="is-size-5-custom is-vertical-center has-text-centered add-horizontal-center"
                   :class="{ 'min-text-height box-margin-top': !this.isMobile, 'min-text-height-mobile box-margin-top-mobile': this.isMobile }">
                    {{ $t('kmso.home.project_management_fact_4') }}
                </p>
                <br>
                <div class="has-text-centered" :class="{ 'min-text-footer-height': !this.isMobile }">
                    <div onclick="window.open('https://www.cio.com/article/3174516/it-project-success-rates-finally-improving.html', '_blank')" class="pointer-hand text-blue">
                        CIO
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Startseite-Projektmanagement-Circle-1',
        props: [
            'isMobile',
        ],
    }
</script>
