<template>
    <div>
        <article class="message">
            <div class="message-body message-border-color-3">
                <div class="columns">
                    <div class="column is-12">
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet" background-shadow="shadow-message-background">
                            <p class="is-size-5-custom is-italic" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                Seit über 3 Jahren arbeiten wir bei finbc mit Jan Bickel und Roman Berneburg zusammen. Sie unterstützen uns im Rahmen der Konzeptionierung, der technischen Architektur und agilen Entwicklung verschiedener Softwareprojekte sowie diverser Banking API Anbindungen im Bereich Finanzdienstleistungen. Jan und Roman sind sehr erfahren in der Entwicklung und stets bereit neues auszuprobieren und schnell zu beherrschen. Besonders hervorzuheben ist ihr Zeitmanagement und Engagement für das Projekt. Sie versuchen die Anforderungen in der Tiefe zu verstehen, fragen proaktiv bei Unklarheiten nach und setzen diese pragmatisch und schnell um. Mit Jan und Roman zusammenzuarbeiten macht einfach Spaß und ist dennoch immer sehr professionell. Wer ein Softwareprojekt qualitativ und schnell umsetzen möchte ist bei KMSO definitiv an der richtigen Adresse.
                            </p>
                        </read-more>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <p class="is-size-5-custom has-text-right">
                            <strong>Stefan Grimm</strong>
                        </p>
                        <p class="is-size-5-custom has-text-right">
                            Gründer & CEO
                        </p>
                        <p class="is-size-5-custom has-text-right">
                            finbc GmbH
                        </p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Referenzen-Kunden-Zitate-2',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
