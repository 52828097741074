<template>
    <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
        <router-link class="is-size-4-custom" :to="{ name: 'kontakt' }">
            {{ $t('kmso.interest_small.text') }} &#8811;
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Interesse-Klein',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>

