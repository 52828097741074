<template>
    <form ref="form_normal_contact">
        <div class="columns is-centered">
            <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                <div class="field">
                    <div class="is-size-5-custom"><strong>{{ $t('kmso.contact.contact_form_your_email') }}</strong></div>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            id="emailContact"
                            class="input"
                            :class="{ 'is-danger': errorEmail }"
                            type="email"
                            :placeholder="$t('kmso.contact.contact_form_email')"
                            v-model="email"
                            required
                            @blur="this.$validateInput">
                        <span class="icon is-small is-left">
                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }"/>
                        </span>
                        <span class="icon is-small is-right">
                            <font-awesome-icon id="emailContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                            <font-awesome-icon id="emailContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                        </span>
                    </div>
                </div>
            </div>
            <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                <div class="field">
                    <div class="is-size-5-custom"><strong>{{ $t('kmso.contact.contact_form_your_telephone') }}</strong></div>
                    <div class="control has-icons-left has-icons-right">
                        <input
                            id="phoneContact"
                            class="input"
                            type="text"
                            :placeholder="$t('kmso.contact.contact_form_telephone')"
                            v-model="phone"
                            @blur="this.$validateInput">
                        <span class="icon is-small is-left">
                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }"/>
                        </span>
                        <span class="icon is-small is-right">
                            <font-awesome-icon id="phoneContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                            <font-awesome-icon id="phoneContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                <div class="field">
                    <div class="is-size-5-custom"><strong>{{ $t('kmso.contact.contact_form_how_can_we_help') }}</strong></div>
                    <div class="control">
                        <div class="select is-fullwidth">
                            <select v-model="subject">
                                <option value="1" selected>{{ $t('kmso.contact.subject_choose') }}</option>
                                <option value="2">{{ $t('kmso.contact.subject_1') }}</option>
                                <option value="3">{{ $t('kmso.contact.subject_2') }}</option>
                                <option value="4">{{ $t('kmso.contact.subject_3') }}</option>
                                <option value="5">{{ $t('kmso.contact.subject_4') }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                <div class="field">
                    <div class="control">
                        <div
                            class="kmso-contact-message border-radius-4"
                            :class="{ 'kmso-box-shadow': boxSelected, 'kmso-border-danger': errorState, 'kmso-border-danger-focus': boxSelectedError }">

                            {{ $t('kmso.contact.salutation') }}
                            <br>

                            <div class="control has-icons-right">
                                <textarea
                                    id="messageContact"
                                    class="textarea"
                                    v-on:focusin="setSelectedState"
                                    v-on:focusout="unsetSelectedState"
                                    :placeholder="$t('kmso.contact.contact_form_your_message')"
                                    v-model="message"
                                    required
                                    @blur="this.$validateInput">
                                </textarea>
                                <span class="icon is-small is-right">
                                    <font-awesome-icon id="messageContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                                    <font-awesome-icon id="messageContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                                </span>
                            </div>

                            {{ $t('kmso.contact.greetings') }}
                            <br>

                            <div class="control has-icons-right">
                                <input
                                    id="fromNameContact"
                                    class="input"
                                    v-on:focusin="setSelectedState"
                                    v-on:focusout="unsetSelectedState"
                                    type="text"
                                    :placeholder="$t('kmso.contact.contact_form_your_name')"
                                    v-model="username"
                                    @blur="this.$validateInput">
                                <span class="icon is-small is-right">
                                    <font-awesome-icon id="fromNameContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                                    <font-awesome-icon id="fromNameContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                <div class="field">
                    <div class="custom-checkbox-wrapper" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <input
                               id="agreeContact"
                               name="agreeContact"
                               type="checkbox"
                               class="is-checkradio custom-checkradio"
                               v-model="privacy">
                        <label id="agreeContactLabel" for="agreeContact" class="is-size-6 custom-checkbox-color">
                            {{ $t('kmso.general.confirm_privacy') }}
                            <router-link :to="{ name: 'datenschutz' }">
                                <div class="display-inline-block">
                                    {{ $t('kmso.general.data_protection') }}
                                </div>
                            </router-link>.
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="mailSuccess" class="columns is-centered">
            <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                <article class="message">
                    <div class="message-body is-size-5-custom message-border-color-9">
                        {{ $t('kmso.contact.contact_form_sent_successfully') }}
                    </div>
                </article>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                <button
                    type="button"
                    class="button is-primary is-size-5-custom"
                    :class="{ 'is-loading': sendingInProgress, 'transition-up move-up-on-hover': !sendingInProgress && privacy }"
                    :disabled="!this.isMobile && !privacy"
                    @click="sendMail">
                    {{ $t('kmso.contact.contact_form_send_message') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "ContactFormNormal",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                email: null,
                phone: null,
                subject: 1,
                errorEmail: null,
                errorMessage: null,
                mailSuccess: null,
                sendingInProgress: null,
                privacy: null,
                sending: null,
                boxSelected: false,
                boxSelectedError: false,
                message: null,
                username: null,
                errorState: false,
            }
        },
        methods: {
            sendMail() {
                if (this.$refs.form_normal_contact.checkValidity() && this.privacy) {
                    this.sendingInProgress = true;
                    this.errorEmail = null;
                    this.errorMessage = null;
                    this.mailSuccess= null;
                    this.errorState = false;

                    let subjectMessage = this.$t('kmso.contact.subject_choose');
                    if (this.subject === '2') {
                        subjectMessage = this.$t('kmso.contact.subject_1');
                    } else if (this.subject === '3') {
                        subjectMessage = this.$t('kmso.contact.subject_2');
                    } else if (this.subject === '4') {
                        subjectMessage = this.$t('kmso.contact.subject_3');
                    } else if (this.subject === '5') {
                        subjectMessage = this.$t('kmso.contact.subject_4');
                    }

                    window.axios
                        .post('mail/send', {
                            email: this.email,
                            phone: this.phone,
                            message: this.message.trim().length > 0 ? this.message : "Empty Message",
                            subject: subjectMessage,
                            name: this.username,
                            privacy: this.privacy,
                        })
                        .then(() => {
                            this.mailSuccess = true;
                            this.sendingInProgress = null;
                        })
                        .catch(error => {
                            if (error.response.data.errors) {
                                const errors = error.response.data.errors;

                                if (errors.email) {
                                    this.errorEmail = true;
                                }

                                if (errors.message) {
                                    this.errorMessage = true;
                                    this.errorState = true;
                                }
                            }
                            this.sendingInProgress = null;
                        });
                } else {
                    this.$refs.form_normal_contact.reportValidity();
                }
            },
            setSelectedState() {
                if (!this.errorState) {
                    this.boxSelected = true;
                } else {
                    this.boxSelectedError = true;
                }
            },
            unsetSelectedState() {
                this.boxSelected = false;
                this.boxSelectedError = false;
            },
        }
    }
</script>

<style scoped>

</style>
