<template>
    <div>
        <article class="message">
            <div class="message-body message-border-color-3">
                <div class="columns">
                    <div class="column is-12">
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet" background-shadow="shadow-message-background">
                            <p class="is-size-5-custom is-italic" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                Die KMSO IT-Dienstleistungen GmbH hat uns mit vollster Zufriedenheit im Rahmen eines Softwareprojekts von der Konzeption bis zur Umsetzung unterstützt. Insbesondere die hohe Geschwindigkeit und das agile Vorgehen bei der Umsetzung sind hervorzuheben. Ferner war unsere Zusammenarbeit von hoher Professionalität und umgehenden Reaktionen auf Anfragen sowie Anpassungsbedarfe geprägt. Zusammengefasst haben wir einen erstklassigen Service erhalten und nehmen diesen für weitere Anfragen gerne wieder in Anspruch.
                            </p>
                        </read-more>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <p class="is-size-5-custom has-text-right">
                            <strong>Dr. André Schweizer</strong>
                        </p>
                        <p class="is-size-5-custom has-text-right">
                            Geschäftsführer
                        </p>
                        <p class="is-size-5-custom has-text-right">
                            qbound GmbH
                        </p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Referenzen-Kunden-Zitate-1',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
