<template>
    <article class="message">
        <div class="message-body message-border-color-9">
            <div class="columns">
                <div class="column is-12">
                    <div class="content">
                        <p class="is-size-5-custom">
                            <i>
                                {{ $t('kmso.blog.comment_info', {date: date, time: time, name: name}) }}
                            </i>
                        </p>

                        <p class="is-size-5-custom">
                            {{ comment }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        name: "BlogComment",
        props: [
            'name',
            'comment',
            'date',
            'time',
        ]
    }
</script>

<style scoped>

</style>
