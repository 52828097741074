<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/blog.svg" :alt="$t('kmso.blog.title_mobile')" class="is-transparent-3 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.blog.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.blog.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/blog.svg" :alt="$t('kmso.blog.title_desktop')" class="is-transparent-3 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.blog.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.blog.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div v-if="id" class="container">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ headline }}
                    </h3>
                    <br>
                    <div class="columns">
                        <div class="column is-12">
                            <article class="message">
                                <div class="message-body message-border-color-9">
                                    <div class="columns is-vcentered">
                                        <div v-if="imageUrl" class="column is-2">
                                            <img :src="imageUrl" :alt="$t('kmso.blog.img_1')" class="is-horizontal-center border-radius-4">
                                        </div>
                                        <div class="column has-text-left"
                                             :class="{ 'is-10': imageUrl, 'is-12': !imageUrl}">
                                            <div class="columns">
                                                <div class="column is-12">
                                                    <h4 class="is-size-4-custom text-primary">
                                                        <strong>
                                                            {{ headline }}
                                                        </strong>
                                                    </h4>
                                                    <p class="is-size-5-custom">
                                                        <i>
                                                            {{ $t('kmso.blog.blog_post_meta', {date: date, name: author}) }}
                                                        </i>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="columns is-vcentered">
                                                <div class="column is-12">
                                                    <div class="content is-size-5-custom">
                                                        <span v-html="text"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-12">
                                                    <div class="is-size-5 has-text-centered display-flex"
                                                         :class="{ 'icons-left': this.isMobile && !this.isTablet, 'icons-right': !this.isMobile || this.isTablet }">
                                                        <div class="box icon-box-blog padding-left-right">
                                                            <font-awesome-icon class="margin-right-1" :icon="{ prefix: 'fas', iconName: 'comment-alt' }"/>{{ comments }}
                                                        </div>
                                                        <div class="space-between-columns-half"></div>
                                                        <div class="box icon-box-blog padding-left-right pointer-hand share-button" @click="likePost">
                                                            <font-awesome-icon class="margin-right-1" :icon="{ prefix: 'fas', iconName: 'thumbs-up' }"/>{{ likes }}
                                                        </div>
                                                        <div class="space-between-columns-half"></div>
                                                        <div class="box icon-box-blog share-button pointer-hand"
                                                             @click="openSocialShareMenu">
                                                            <social-share-button ref="socialShareButtons" :social-media-url="urlSocialMedia" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                           {{ $t('kmso.blog.blog_post_comments') }}
                        </strong>
                    </h4>
                    <br>
                    <div class="columns">
                        <div class="column is-12">
                            <write-blog-comment :post-id="id" v-if="id" v-on:new="newCommentWritten"/>
                            <blog-comments :post-id="id" v-if="id" ref="comments" v-on:loaded="reloadCommentAmount"/>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.blog.blog_post_not_found') }}
                    </p>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="white-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import SocialShareButton from "../components/SocialShareButton";
    import WriteBlogComment from "../components/WriteBlogComment";
    import BlogComments from "../components/BlogComments";

    export default {
        name: "BlogPost",
        props: [
            'isMobile',
            'isTablet',
        ],
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            SocialShareButton,
            WriteBlogComment,
            BlogComments,
        },
        data() {
            return {
                id: null,
                headline: null,
                text: null,
                date: null,
                url: null,
                urlSocialMedia: null,
                author: null,
                likes: 0,
                comments: 0,
                imageUrl: null,
                isLiked: false,
                jsonld: null
            }
        },
        methods: {
            reloadCommentAmount() {
                this.comments = this.$refs.comments.getAmount();
            },
            newCommentWritten() {
                this.$refs.comments.loadComments();
            },
            likePost() {
                if (this.isLiked) {
                    window.axios.post('blog/like-post-remove', {
                        id: this.id
                    })
                    .then(response => {
                        this.likes = response.data.likes;
                        this.isLiked = false;
                    });
                } else {
                    window.axios.post('blog/like-post', {
                        id: this.id
                    })
                    .then(response => {
                        this.likes = response.data.likes;
                        this.isLiked = true;
                    });
                }
            },
            loadBlogPost() {
                window.axios.get('blog/load-post', {
                    params: {
                        url: this.$route.params.url,
                        language: this.$i18n.locale,
                    }
                })
                .then(response => {
                    const data = response.data;

                    this.id = data.id;
                    this.headline = data.headline;
                    this.text = data.text;
                    this.date = data.date;
                    this.url = data.url;
                    this.urlSocialMedia = data.url_social_media;
                    this.author = data.author;
                    this.likes = data.likes;
                    this.comments = data.comments;
                    this.imageUrl = data.imageUrl;

                    // Update meta data with preview text
                    const tempDivTextPreview = document.createElement("div");
                    tempDivTextPreview.innerHTML = data.textPreview;
                    const previewText = tempDivTextPreview.textContent || tempDivTextPreview.innerText;

                    if (previewText) {
                        this.$updateMetaDataDescription(previewText);
                    }

                    this.jsonld = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": this.$i18n.t("kmso.app.menu_blog"),
                            "item": window.location.protocol + "\/\/" + window.location.host + "/blog"
                        }, {
                            "@type": "ListItem",
                            "position": 2,
                            "name": this.headline,
                            "item": window.location.protocol + "\/\/" + window.location.host + "/blog/" + this.url
                        }]
                    };

                    this.$allComponentsLoaded(this.isMobile);
                })
                .catch(error => {
                    this.$allComponentsLoaded(this.isMobile);
                });
            },
            openSocialShareMenu() {
                this.$refs.socialShareButtons.toggleDropdownMenuShare();
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.loadBlogPost();
            });
        }
    }
</script>

<style scoped>

</style>
