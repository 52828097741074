<template>
    <div>
        <div class="bubble-wrapper">
            <div v-if="showEasyContactMessage">
                <div class="bubble shadow-effect" :class="{ 'bubble-max-width': this.isMobile && !this.isTablet }">
                    <div class="max-width-box">
                        <div class="display-flex">
                            <div class="box-image box-image-1"></div>
                            <div class="box-image box-image-2"></div>
                        </div>
                        <button class="delete bubble-close big-delete"
                                aria-label="close"
                                @click="this.closeEasyContactMessage" />
                        <div>
                            <p class="is-size-5-custom margin-right-to-delete">
                                <strong>{{ $t('kmso.general.questions_about_service') }}</strong>
                            </p>
                            <br>
                            <form ref="form_easy_contact">
                                <div class="field">
                                    <p class="is-size-6">{{ $t('kmso.general.your_email_address') }}</p>
                                    <div class="control has-icons-right">
                                        <input
                                            id="emailEasyContact"
                                            class="input"
                                            :class="{ 'is-danger': errorEmail }"
                                            type="email"
                                            :placeholder="$t('kmso.general.email')"
                                            v-model="email"
                                            required
                                            @blur="this.$validateInput">
                                        <span class="icon is-small is-right">
                                            <font-awesome-icon id="emailEasyContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                                            <font-awesome-icon id="emailEasyContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                                        </span>
                                    </div>
                                </div>
                                <div class="field">
                                    <p class="is-size-6">{{ $t('kmso.general.your_message') }}</p>
                                    <div class="control has-icons-right">
                                        <textarea
                                            id="messageEasyContact"
                                            class="textarea"
                                            :class="{ 'is-danger': errorMessage, 'textarea-contact-height-mobile': this.isMobile && !this.isTablet, 'textarea-contact-height-desktop': !this.isMobile || this.isTablet }"
                                            :placeholder="$t('kmso.general.message')"
                                            v-model="message"
                                            required
                                            @blur="this.$validateInput">
                                        </textarea>
                                        <span class="icon is-small is-right">
                                            <font-awesome-icon id="messageEasyContactSuccess" class="display-none has-text-success" :icon="{ prefix: 'fa', iconName: 'check' }"/>
                                            <font-awesome-icon id="messageEasyContactWarning" class="display-none has-text-warning" :icon="{ prefix: 'fa', iconName: 'exclamation-triangle' }"/>
                                        </span>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="custom-checkbox-wrapper" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                        <input id="agreeEasyContact"
                                               name="agreeEasyContact"
                                               type="checkbox"
                                               class="is-checkradio custom-checkradio"
                                               v-model="privacy">
                                        <label id="agreeContactLabel" for="agreeEasyContact" class="is-size-6 custom-checkbox-color">
                                            {{ $t('kmso.general.confirm_privacy') }}
                                            <router-link :to="{ name: 'datenschutz' }">
                                                <div class="display-inline-block"
                                                     @click="this.closeEasyContactMessage">
                                                    {{ $t('kmso.general.data_protection') }}
                                                </div>
                                            </router-link>.
                                        </label>
                                    </div>
                                </div>
                                <article v-if="mailSuccess" class="message">
                                    <div class="message-body message-border-color-9 message-body-contact">
                                        <p class="is-size-6">{{ $t('kmso.general.mail_sent_successfully') }}</p>
                                    </div>
                                </article>
                                <button type="button"
                                        class="button is-primary is-fullwidth is-size-5-custom"
                                        :class="{'is-loading': sendingInProgress, 'transition-up move-up-on-hover': !sendingInProgress && privacy}"
                                        :disabled="!this.isMobile && !privacy"
                                        @click="this.sendEasyMail">
                                    {{ $t('kmso.general.send_message') }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!hideBubble">
                <div class="bubble shadow-effect">
                    <div class="max-width-box">
                        <div class="display-flex">
                            <div class="box-image box-image-1"></div>
                            <div class="space-between-box-images"></div>
                            <div class="box-image box-image-2"></div>
                        </div>
                        <button class="delete bubble-close"
                                aria-label="close"
                                @click="this.closeBubble"
                        />
                        <p class="is-size-6">{{ $t('kmso.general.questions_about_service') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="easy-contact-to-element action-button-page">
                <div class="easy-contact-to-element-background action-button-page-background shadow-effect border-radius-4"
                     @click="this.openEasyContactMessage">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'comment' }" class="is-size-2"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Easy-Contact',
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                hideBubble: true,
                showEasyContactMessage: null,
                email: null,
                message: null,
                privacy: null,
                errorEmail: null,
                errorMessage: null,
                mailSuccess: null,
                sendingInProgress: null,
            }
        },
        methods: {
            closeBubble() {
                this.hideBubble = true;
            },
            closeEasyContactMessage() {
                this.showEasyContactMessage = null;
            },
            openEasyContactMessage() {
                this.hideBubble = true;
                this.showEasyContactMessage = !this.showEasyContactMessage;

                if (this.isMobile) {
                    this.$emit('openCloseEasyContact');
                }
            },
            sendEasyMail() {
                if (this.$refs.form_easy_contact.checkValidity() && this.privacy) {
                    this.sendingInProgress = true;

                    window.axios
                        .post('mail/send', {
                            email: this.email,
                            message: this.message.trim().length > 0 ? this.message : "Empty Message",
                            privacy: this.privacy,
                        })
                        .then(() => {
                            this.errorEmail = false;
                            this.errorMessage = false;

                            this.mailSuccess = true;

                            this.sendingInProgress = null;
                        })
                        .catch(error => {
                            this.errorEmail = false;
                            this.errorMessage = false;

                            const errors = error.response.data.errors;
                            if (errors) {
                                if (errors.email) {
                                    this.errorEmail = true;
                                }

                                if (errors.message) {
                                    this.errorMessage = true;
                                }
                            }

                            this.sendingInProgress = null;
                        });
                } else {
                    this.$refs.form_easy_contact.reportValidity();
                }
            },
        },
        mounted() {
            this.hideBubble = this.isMobile;
        }
    };
</script>

<style scoped>
    .bubble-wrapper {
        position: fixed;
        z-index: 38;
        bottom: 7em;
        right: 20px;
    }

    .bubble {
        float: right;
        border-radius: 4px;
        padding: 15px;
    }

    .bubble-max-width {
        max-width: 95%;
    }

    .bubble::after {
        position: absolute;
        bottom: -19px;
        right: 2em;
        border-left: 20px solid transparent;
        content: "";
    }

    .bubble-close {
        position: relative;
        float: right;
    }

    .max-width-box {
        max-width: 22em;
    }

    .box-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: -40px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;
    }

    .box-image-1 {
        background-image: url('/img/kontaktbox1.png');
    }

    .box-image-2 {
        background-image: url('/img/kontaktbox2.png');
    }

    .textarea-contact-height-desktop {
        min-height: 7em !important;
    }
    .textarea-contact-height-mobile {
        min-height: 5em !important;
    }

    .margin-right-to-delete {
        margin-right: 35px;
    }
</style>
