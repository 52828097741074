<template>
    <section>
        <div class="columns">
            <div class="column is-offset-one-quarter is-half">
                <div class="field">
                    <label class="label">Beschreibung der Tätigkeit</label>
                    <div class="control">
                        <textarea class="textarea" :class="{'is-danger': !descriptionValid}" v-model="description"></textarea>
                    </div>
                </div>

                <div v-if="!descriptionValid" class="notification is-primary">
                    Die Beschreibung kann nicht leer sein.
                </div>

                <div class="columns">
                    <div class="column is-narrow">
                        <div class="field">
                            <label class="label">Stunden</label>
                            <div class="select">
                                <select v-model="hours" :class="{'is-danger': !timeValid}">
                                    <option v-for="n in 24" :value="n-1">{{ n-1 }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="label">Minuten</label>
                            <div class="select">
                                <select v-model="minutes" :class="{'is-danger': !timeValid}">
                                    <option v-for="n in 12" :value="(n-1)*5">{{ (n-1)*5 }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!timeValid" class="notification is-primary">
                    Die angegebene Zeit ist nicht korrekt. Eine Dauer von 0 Minuten kann nicht vermerkt werden.
                </div>

                <button class="button is-primary" v-on:click="updateTimerecord">Update</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "EditTimerecord",
    data() {
        return {
            description: null,
            hours: 0,
            minutes: 0,
            descriptionValid: true,
            timeValid: true,
        }
    },
    mounted() {
        this.loadTimerecords();
    },
    methods: {
        loadTimerecords() {
            window.axios
                .get('backend/timerecording/edit-timerecord/' + this.$route.params.id, {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    let data = response.data;

                    this.description = data.description;
                    this.hours = data.hours;
                    this.minutes = data.minutes;
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
        updateTimerecord() {
            this.timeValid = true;
            this.descriptionValid = true;

            let timeInMinutes = this.hours * 60 + this.minutes;

            if(timeInMinutes === 0) {
                this.timeValid = false;
            }

            if(this.description === null || this.description.trim().length === 0) {
                this.descriptionValid = false;
            }

            if(this.timeValid && this.descriptionValid) {
                window.axios
                    .post('backend/timerecording/update-timerecord', {
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()},
                        id: this.$route.params.id,
                        description: this.description,
                        timeLogged: timeInMinutes,
                    })
                    .then(() => {
                        window.location = '/backend/timerecording/administration';
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            }
        }
    }
}
</script>

<style scoped>

</style>
