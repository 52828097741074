<template>
    <section>
        <div v-if="$auth.ready()">
            <router-view></router-view>
        </div>

        <div v-if="!$auth.ready()">
            Lade ...
        </div>
    </section>
</template>

<script>
    export default {
        name: "BackendApp"
    }
</script>

<style scoped>

</style>
