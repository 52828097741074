<template>
<p>{{ message }}</p>
</template>

<script>
export default {
    name: "Logout",
    data() {
        return {
            message: 'Lade...',
        }
    },
    mounted() {
        this.logout();
    },
    methods: {
        logout() {
            this.$auth.logout();
        }
    }
}
</script>

<style scoped>

</style>
