import Vue from 'vue';
import Buefy from 'buefy';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import MessagesDe from './langs/de';
import MessagesEn from './langs/en';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faTimes, faCheck, faPen, faTrash, faShareAlt, faCommentAlt, faThumbsUp, faComment, faEnvelope, faPhone, faMapMarkerAlt, faEye, faExclamationTriangle, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import VueAxios from "vue-axios";
import VueMapkit from 'vue-mapkit';
import AOS from 'aos';
import 'regenerator-runtime/runtime';
import 'bulma-checkradio';

require('./bootstrap');

let scrollToElement = require('scroll-to-element');
let SocialSharing = require('vue-social-sharing');

library.add(faWhatsapp);
library.add(faTimes);
library.add(faCheck);
library.add(faPen);
library.add(faTrash);
library.add(faShareAlt);
library.add(faCommentAlt);
library.add(faThumbsUp);
library.add(faComment);
library.add(faEnvelope);
library.add(faPhone);
library.add(faMapMarkerAlt);
library.add(faEye);
library.add(faExclamationTriangle);
library.add(faFilePdf);

Vue.use(Buefy);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(SocialSharing);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('easy-contact', require('./components/easy-contact.vue').default);
Vue.component('read-more', require('./components/read-more.vue').default);
Vue.component('footer-element', require('./components/footer-element.vue').default);
Vue.component('scroll-to-element', require('./components/scroll-to-element.vue').default);
Vue.component('carousel', require('./components/carousel.vue').default);

import App from './views/App'
import Startseite from './views/Startseite'
import Leistungen from './views/Leistungen'
import Referenzen from './views/Referenzen'
import Karriere from './views/Karriere'
import UeberKmso from './views/Ueberkmso'
import Blog from './views/Blog'
import Kontakt from "./views/Kontakt"
import Projektmanagement from "./views/Projektmanagement"
import Softwareentwicklung from "./views/Softwareentwicklung"
import Impressum from "./views/Impressum"
import Datenschutz from "./views/Datenschutz"

import BackendApp from "./views/backend/BackendApp";
import Index from "./views/backend/Index";
import Login from "./views/backend/Login";
import BackendView from "./views/backend/BackendView";
import PageUnauthorized from "./views/backend/PageUnauthorized";
import PageNotFound from "./views/backend/PageNotFound";
import NewUser from "./views/backend/NewUser";
import NewPost from "./views/backend/blog/NewPost";
import PageEmpty from "./views/PageEmpty";
import Posts from "./views/backend/blog/Posts";
import EditPost from "./views/backend/blog/EditPost";
import BlogPost from "./views/BlogPost";
import Overview from "./views/backend/timerecording/Overview";
import Administration from "./views/backend/timerecording/Administration";
import EditTimerecord from "./views/backend/timerecording/EditTimerecord";
import TimerecordsMonthOverview from "./views/backend/timerecording/TimerecordsMonthOverview";
import Logout from "./views/backend/Logout";
import Statistics from "./views/backend/system/Statistics";

const lang = document.documentElement.lang.substr(0, 2);
const kmso_suffix = ' ' + String.fromCharCode(parseInt('2212', 16)) + ' KMSO';
const displayNone = 'display-none';

const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'de',
    messages: lang === 'de' ? MessagesDe : MessagesEn
});

Vue.prototype.$changeLanguageTop = function changeLanguageTop(newLanguage) {
    i18n.setLocaleMessage(newLanguage, newLanguage === 'de' ? MessagesDe[newLanguage] : MessagesEn[newLanguage]);
    i18n.locale = newLanguage;
    document.querySelector('html').setAttribute('lang', newLanguage);
};

// Mapkit Init
const mapkitToken = document.getElementById('kmso-mapkit-token');
if (mapkitToken) {
    Vue.use(VueMapkit, {
        authorizationCallback(done) {
            done(mapkitToken.getAttribute("content"))
        },
        language: lang ? lang : 'de',
    });
}

// Validation for Contact-Form-Fields
Vue.prototype.$validateInput = function validateInput(e) {
    const elementId = e.target.id;

    const inputOkElement = document.getElementById(elementId + 'Success');
    const inputWarningElement = document.getElementById(elementId + 'Warning');

    if(!e.target.validity.valid) {
        if (!inputOkElement.classList.contains(displayNone)) {
            inputOkElement.classList.add(displayNone);
        }
        if (inputWarningElement.classList.contains(displayNone)) {
            inputWarningElement.classList.remove(displayNone);
        }
    } else if(e.target.validity.valid) {
        if (inputOkElement.classList.contains(displayNone)) {
            inputOkElement.classList.remove(displayNone);
        }
        if (!inputWarningElement.classList.contains(displayNone)) {
            inputWarningElement.classList.add(displayNone);
        }
    }
};

// Global Variable: Holds VueViewLoaded and Theme. (Extra Vue-Component)
let globalData = new Vue({
    data: { $pageFullyLoaded: null, $theme: null, $savedPosition: null }
});
Vue.mixin({
    computed: {
        $pageFullyLoaded: {
            get: function () { return globalData.$data.$pageFullyLoaded },
            set: function (padeLoaded) { globalData.$data.$pageFullyLoaded = padeLoaded; }
        },
        $theme: {
            get: function () { return globalData.$data.$theme },
            set: function (theme) { globalData.$data.$theme = theme; }
        },
        $savedPosition: {
            get: function () { return globalData.$data.$savedPosition },
            set: function (savedPosition) { globalData.$data.$savedPosition = savedPosition; }
        }
    }
});

// Called on every route. On iPhone also called when go to anchors
let firstStart = true;
const router = createRouter();
router.beforeEach((to, from, next) => {
    if (from.name !== to.name) {
        globalData.$data.$pageFullyLoaded = null;
        Vue.prototype.$updateMetaData(to, false);

        Vue.axios
            .post('statistics', {
                route: to.path
            });
    }

    if (firstStart) {
        firstStart = false;
        Vue.prototype.$initTheme();
    }

    next();
});

// Get scrollTop
Vue.prototype.$getScrollTop = function getScrollTop() {
    let verticalPosition = 0;

    if (pageYOffset) {
        verticalPosition = pageYOffset;
    }
    else if (document.documentElement.clientHeight) {
        verticalPosition = document.documentElement.scrollTop;
    }
    else if (document.body) {
        verticalPosition = document.body.scrollTop;
    }

    verticalPosition = Number(verticalPosition);

    return isNaN(verticalPosition) ? 0 : verticalPosition.toFixed();
};

// Called if orientation changes. It is used on the Apps Vue Created Methode
Vue.prototype.$windowOrientationEvent = function windowOrientationEvent() {
    const verticalPosition = Vue.prototype.$getScrollTop();
    location.href = window.location.protocol + "\/\/" + window.location.host + window.location.pathname + "?scroll=" + verticalPosition;
};

// This method is important for some mobile or tablet views with navigation bars that may appear
Vue.prototype.$setCalculatedHeights = function setCalculatedHeights() {
    const querySelectorMainHero = document.getElementsByClassName("hero-main-section-1");

    if (querySelectorMainHero.length === 1) {
        const mainHeroHeight = querySelectorMainHero[0].offsetHeight;

        querySelectorMainHero[0].style.minHeight = 'unset';
        querySelectorMainHero[0].style.height = `${mainHeroHeight}px`;
    }

    const querySelectorMainHeroSection1 = document.getElementsByClassName("hero-main-section-2");

    if (querySelectorMainHeroSection1.length === 1) {
        const mainHeroHeightSection1 = querySelectorMainHeroSection1[0].offsetHeight;

        querySelectorMainHeroSection1[0].style.minHeight = 'unset';
        querySelectorMainHeroSection1[0].style.height = `${mainHeroHeightSection1}px`;
    }

    const querySelectorMainHeroSection2 = document.getElementsByClassName("hero-main-section-3");

    if (querySelectorMainHeroSection2.length === 1) {
        const mainHeroHeightSection2 = querySelectorMainHeroSection2[0].offsetHeight;

        querySelectorMainHeroSection2[0].style.minHeight = 'unset';
        querySelectorMainHeroSection2[0].style.height = `${mainHeroHeightSection2}px`;
    }

    const querySelectorMapKitMap = document.getElementsByClassName("mapkit_map");

    if (querySelectorMapKitMap.length === 1) {
        const mapkitMapHeight = querySelectorMapKitMap[0].offsetHeight;

        querySelectorMapKitMap[0].style.minHeight = 'unset';
        querySelectorMapKitMap[0].style.height = `${mapkitMapHeight}px`;
    }
};

// This method is not unused. It is used on every VueView to set the content to visible and scroll to element
Vue.prototype.$allComponentsLoaded = function allComponentsLoaded(isMobile) {
    globalData.$data.$pageFullyLoaded = true;

    this.$nextTick(() => {
        if (isMobile) {
            Vue.prototype.$setCalculatedHeights();
        }

        if (globalData.$data.$savedPosition) {
            window.scroll(0, globalData.$data.$savedPosition);
        }
        else {
            const anchor = this.$router.currentRoute.hash;
            if (anchor) {
                const querySelectorAnchor = document.getElementById(anchor.substr(1));
                if (querySelectorAnchor) {
                    setTimeout(() => {
                        document.querySelector(anchor).scrollIntoView();
                    }, 100);
                }
            }
            else {
                Vue.prototype.$orientationScroll();
            }
        }
    });
};

// Scroll to scroll Position
Vue.prototype.$orientationScroll = function orientationScroll() {
    let scrollValue = Vue.prototype.$getQueryParameter('scroll');
    if (scrollValue) {
        scrollValue = parseInt(scrollValue);
        if (Number.isInteger(scrollValue)) {
            setTimeout(() => {
                window.scroll(0, scrollValue);
            }, 50);
        }
    }
};

// Get query parameter
Vue.prototype.$getQueryParameter = function getQueryParameter(param) {
    const paramFound = RegExp('[?&]' + param + '=([^&]*)').exec(location.search);
    return paramFound && decodeURIComponent(paramFound[1].replace(/\+/g, ' '));
};

Vue.prototype.$moveToAnchor = function moveToAnchor(anchor) {
    let scrollElement = null;

    if (this.$route.name === anchor) {
        scrollElement = document.body;

        if (this.$refs.routerView && this.$route.name === 'blog') {
            // blog pressed in navbar and current page is blog
            this.$refs.routerView.loadBlogPosts(1);
        }
    }
    else {
        const querySelectorAnchor = document.querySelector(anchor);
        if (querySelectorAnchor) {
            scrollElement = anchor;
        }
    }

    if (scrollElement) {
        scrollToElement(scrollElement, {
            offset: 0,
            ease: 'out-cube',
            duration: 500
        });
    }
};

// Init Theme on first start
Vue.prototype.$initTheme = function initTheme() {
   const defaultThemeElement = document.getElementById('kmso-default-theme');
   if (defaultThemeElement) {
       const defaultTheme = defaultThemeElement.getAttribute("content");
       if (defaultTheme) {
           if (window.matchMedia && !window.matchMedia("(prefers-color-scheme: dark)").matches) {
               globalData.$data.$theme = "light";
           } else {
               globalData.$data.$theme = "dark";
           }
       } else {
           globalData.$data.$theme = document.getElementById('kmso-initial-theme').getAttribute("content");
       }

       Vue.prototype.$changeTheme();
   }
};

// Change the theme
Vue.prototype.$changeTheme = function changeTheme() {
    const cssLink = '/css/bulma-prefers-'+globalData.$data.$theme+'.css';

    const themeCss = document.getElementById('theme-css');
    if (themeCss) {
        themeCss.setAttribute("href", cssLink);
    } else {
        const head  = document.getElementsByTagName('head')[0];
        const link  = document.createElement('link');
        link.id = 'theme-css';
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = cssLink;
        head.appendChild(link);
    }
};

// Update meta data
Vue.prototype.$updateMetaData = function updateMetaData(route, fromLanguageChange) {
    const routeName = route.name;

    if (routeName !== undefined) {
        // Set og language
        const i18nLanguage = i18n.locale;
        if (i18nLanguage) {
            const languageOg = document.getElementById('kmso-meta-language-og');
            if (languageOg) {
                languageOg.setAttribute('content', i18nLanguage);
            }
        }

        // Set og sitename
        const titleSiteName = String(i18n.t('kmso.meta.startseite') + kmso_suffix);
        if (titleSiteName) {
            const sitenameOg = document.getElementById('kmso-meta-sitename-og');
            if (sitenameOg) {
                sitenameOg.setAttribute('content', titleSiteName);
            }
        }

        // Set title and og title
        let titleText = "";
        if (routeName === "blogpost") {
            const routePath = route.path;
            titleText = routePath
                .substring(routePath.lastIndexOf('/') + 1)
                .replaceAll('-', ' ')
                .toLowerCase()
                .split(' ')
                .map(w => w === "kmso" ? "KMSO"
                    : w === "ios" ? "iOS"
                    : w === "iot" ? "IoT"
                    : w === "seo" ? "SEO"
                    : (w === "and" || w === "or" || w === "the" || w === "at" || w === "against" || w === "as" || w === "a" || w === "is" || w === "und" || w === "oder" || w === "der" || w === "die" || w === "das" || w === "bei" || w === "gegen" || w === "als" || w === "ist") ? w
                    : (w === "de" || w === "en" || w === "eng") ? ""
                    : w.charAt(0).toUpperCase() + w.slice(1)
                ).join(' ');
        } else {
            titleText = i18n.t('kmso.meta.'+routeName);
            if (routeName === "startseite") {
                titleText += kmso_suffix;
            }
        }
        titleText = String(titleText);

        if (titleText) {
            // Set title
            const title = document.getElementById('kmso-meta-title');
            if (title) {
                title.innerHTML = titleText;
            }

            // Set og title
            const titleOg = document.getElementById('kmso-meta-title-og');
            if (titleOg) {
                titleOg.setAttribute('content', title.innerHTML);
            }
        }

        // Set description and og description
        if (!fromLanguageChange || (fromLanguageChange && routeName !== "blogpost")) {
            const descriptionText = String(i18n.t('kmso.meta.'+routeName+'_content'));
            if (descriptionText) {
                Vue.prototype.$updateMetaDataDescription(descriptionText);
            }
        }

        // Set og url
        const urlOg = document.getElementById('kmso-meta-url-og');
        if (urlOg) {
            let urlText = window.location.protocol + "\/\/" + window.location.host;
            if (routeName !== "startseite") {
                urlText += "/" + routeName;
            }

            urlOg.setAttribute('content', urlText);
        }
    }
};

// Update meta data description
Vue.prototype.$updateMetaDataDescription = function updateMetaDataDescription(descriptionText) {
    const description = document.getElementById('kmso-meta-description');
    if (description) {
        description.setAttribute('content', descriptionText);
    }

    const descriptionOg = document.getElementById('kmso-meta-description-og');
    if (descriptionOg) {
        descriptionOg.setAttribute('content', descriptionText);
    }
};

Vue.router = router;

Vue.use(VueAxios, axios);

Vue.use(require('@websanova/vue-auth'), {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    tokenDefaultName: 'kmso-backend',
    tokenStore: ['localStorage'],
    rolesVar: 'role',
    notFoundRedirect: {
        path: '/backend/page-not-found'
    },
    forbiddenRedirect: {
        path: '/backend/page-unauthorized'
    },
    authRedirect: {
        path: '/backend/login'
    },
    loginData: {
        url: 'auth/login',
        method: 'POST',
        redirect: '/backend',
        fetchUser: true
    },
    logoutData: {
        url: 'auth/logout',
        method: 'POST',
        redirect: '/backend/login',
        makeRequest: true
    },
    refreshData: {
        url: 'auth/refresh',
        method: 'GET',
        enabled: true,
        interval: 30
    },
    registerData: {
        url: 'auth/register',
        method: 'POST',
        redirect: '/backend/new-user'
    }
});

Vue.axios.defaults.baseURL = '/api/v1';

const app = new Vue({
    el: '#app',
    i18n,
    components: { App, BackendApp },
    router,
    created () {
        AOS.init({
            distance: 10,
            offset: 0,
            duration: 500,
            once: true,
        })
    },
});

function createRouter() {
    return new VueRouter({
        mode: 'history',
        routes: [
            {
                path: '/startseite',
                name: 'startseite',
                component: Startseite,
            },
            {
                path: '/leistungen',
                name: 'leistungen',
                component: Leistungen,
            },
            {
                path: '/leistungen/projekt-management',
                name: 'projekt-management',
                component: Projektmanagement,
            },
            {
                path: '/leistungen/software-entwicklung',
                name: 'software-entwicklung',
                component: Softwareentwicklung,
            },
            {
                path: '/referenzen',
                name: 'referenzen',
                component: Referenzen,
            },
            {
                path: '/karriere',
                name: 'karriere',
                component: Karriere,
            },
            {
                path: '/ueber-kmso',
                name: 'ueber-kmso',
                component: UeberKmso,
            },
            {
                path: '/blog/seite/:page',
                name: 'blog',
                component: Blog,
                alias: '/blog',
            },
            {
                path: '/blog/:url',
                name: 'blogpost',
                component: BlogPost,
            },
            {
                path: '/kontakt',
                name: 'kontakt',
                component: Kontakt,
            },
            {
                path: '/impressum',
                name: 'impressum',
                component: Impressum,
            },
            {
                path: '/datenschutz',
                name: 'datenschutz',
                component: Datenschutz,
            },
            {
                path: '/backend',
                component: BackendView,
                children: [
                    {
                        path: '',
                        component: Index,
                        meta: {
                            auth: true
                        }
                    },
                    {
                        path: 'blog',
                        component: PageEmpty,
                        children: [
                            {
                                path: 'new-post',
                                component: NewPost,
                                meta: {
                                    auth: true
                                }
                            },
                            {
                                path: 'posts',
                                component: Posts,
                                meta: {
                                    auth: true
                                }
                            },
                            {
                                path: 'edit-post/:id',
                                component: EditPost,
                                meta: {
                                    auth: true
                                }
                            }
                        ]
                    },
                    {
                        path: 'timerecording',
                        component: PageEmpty,
                        children: [
                            {
                                path: 'overview',
                                component: Overview,
                                meta: {
                                    auth: true
                                }
                            },
                            {
                                path: 'administration',
                                component: Administration,
                                meta: {
                                    auth: {roles: ['admin']}
                                }
                            },
                            {
                                path: 'edit-timerecord/:id',
                                component: EditTimerecord,
                                meta: {
                                    auth: {roles: ['admin']}
                                }
                            },
                            {
                                path: 'month-overview/:userId/:date',
                                component: TimerecordsMonthOverview,
                                meta: {
                                    auth: {roles: ['admin']}
                                }
                            }
                        ]
                    },
                    {
                        path: 'system',
                        component: PageEmpty,
                        children: [
                            {
                                path: 'statistics',
                                component: Statistics,
                                meta: {
                                    auth: {roles: ['admin']}
                                }
                            },
                        ]
                    },
                    {
                        path: 'login',
                        component: Login,
                        meta: {
                            auth: false
                        }
                    },
                    {
                        path: 'logout',
                        component: Logout,
                        meta: {
                            auth: undefined
                        }
                    },
                    {
                        path: 'new-user',
                        component: NewUser,
                        meta: {
                            auth: {roles: ['admin']}
                        }
                    },
                    {
                        path: 'page-not-found',
                        component: PageNotFound,
                        meta: {
                            auth: undefined
                        }
                    },
                    {
                        path: 'page-unauthorized',
                        component: PageUnauthorized,
                        meta: {
                            auth: undefined
                        }
                    }
                ]
            }
        ],
        scrollBehavior(to, from, savedPosition) {
            if (to.name === from.name) {
                return;
            }

            globalData.$data.$savedPosition = null;
            if (savedPosition) {
                globalData.$data.$savedPosition = savedPosition.y;
            }

            return { x: 0, y: 0 }
        },
    });
}
