<template>
    <article class="message">
        <div class="message-body message-border-color-9">
            <div class="columns">
                <div class="column is-12">
                    <div class="field">
                        <div class="is-size-5-custom" v-if="!expand"><strong>{{ $t('kmso.blog.blog_post_participate_in_discussion') }}</strong></div>
                        <div class="is-size-5-custom" v-if="expand"><strong>{{ $t('kmso.blog.blog_post_your_name_alias') }}</strong></div>
                        <div class="control">
                            <input
                                class="input"
                                :class="{'is-danger': !comment.validation.name}"
                                type="text"
                                v-model="comment.content.name"
                                :placeholder="commentPlaceholder"
                                v-on:click="expandComment">
                            <p class="is-size-6-custom help is-danger" v-if="!comment.validation.name">{{ $t('kmso.blog.comment_error_name') }}</p>
                        </div>
                    </div>

                    <div class="field" v-if="expand">
                        <div class="is-size-5-custom"><strong>{{ $t('kmso.blog.blog_post_your_comment') }}</strong></div>
                        <div class="control">
                    <textarea
                        class="textarea"
                        :class="{'is-danger': !comment.validation.comment}"
                        :placeholder="$t('kmso.blog.blog_post_your_comment')"
                        v-model="comment.content.comment">
                    </textarea>
                            <p class="is-size-6-custom help is-danger" v-if="!comment.validation.comment">{{ $t('kmso.blog.comment_error_comment') }}</p>
                        </div>
                    </div>

                    <div class="is-size-5-custom notification is-success" v-if="commentPostedSuccessfully">
                        {{ $t('kmso.blog.comment_post_success') }}
                    </div>

                    <div class="has-text-left" v-if="expand">
                        <button class="button is-primary is-size-5-custom transition-up move-up-on-hover"
                                @click="postComment">
                            {{ $t('kmso.blog.blog_post_send_comment') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        name: "WriteBlogComment",
        props: [
            'postId'
        ],
        data() {
            return {
                comment: {
                    content: {
                        name: null,
                        comment: null,
                    },
                    validation: {
                        name: true,
                        comment: true,
                    }
                },
                expand: null,
                commentPlaceholder: '...',
                commentPostedSuccessfully: null,
            }
        },
        methods: {
            expandComment() {
                if (!this.expand) {
                    this.commentPlaceholder = this.$t('kmso.blog.blog_post_your_name_alias');
                    this.expand = true;
                }
            },
            postComment() {
                this.comment.validation.name = true;
                this.comment.validation.comment = true;
                this.commentPostedSuccessfully = null;

                window.axios.post('blog/post-comment', {
                    id: this.postId,
                    name: this.comment.content.name,
                    comment: this.comment.content.comment,
                })
                .then(response => {
                    if (response.status === 200) {
                        this.comment.content.name = null;
                        this.comment.content.comment = null;
                        this.commentPostedSuccessfully = true;

                        this.$emit('new');
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 422) {
                            const errors = error.response.data.errors;

                            for (let err in errors) {
                                this.comment.validation[err] = null;
                            }
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
