<template>
    <section>
        <b-table
            :data="data"
            paginated
            per-page="15"
        >
            <template slot-scope="props">
                <b-table-column
                    field="headline"
                    label="Beschreibung der Tätigkeit"
                    sortable
                >
                    {{ props.row.description }}
                </b-table-column>

                <b-table-column
                    field="time_logged"
                    label="Dauer"
                    width="100"
                >
                    {{ props.row.time_logged }}
                </b-table-column>

                <b-table-column
                    field="date"
                    label="Datum"
                    sortable
                    width="150"
                >
                    {{ props.row.date }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
export default {
    name: "TimerecordsMonthOverview",
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        this.loadTimerecords();
    },
    methods: {
        loadTimerecords() {
            window.axios
                .get('backend/timerecording/timerecords-for-user/' + this.$route.params.userId + '/' + this.$route.params.date, {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
}
</script>

<style scoped>

</style>
