<template>
    <transition name="transition-action-button-page">
        <div v-if="this.scrollVisible" class="scroll-to-element action-button-page" @click="$moveToAnchor('#allgemein')">
            <div class="scroll-to-element-background action-button-page-background shadow-effect border-radius-4">
                <span class="scroll-to-element-arrow"></span>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Scroll-To-Element',
        data() {
            return {
                scrollVisible: null,
                scrollVisibleHeight: (window.innerHeight*1.5),
            }
        },
        methods: {
            detectScroll() {
                this.scrollVisible = this.$getScrollTop() > this.scrollVisibleHeight;
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.detectScroll);
        },
        mounted() {
            window.addEventListener('scroll', this.detectScroll);
        },
    };
</script>

<style scoped>
    .scroll-to-element {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5em;
    }

    .scroll-to-element-arrow {
        display: inline-block;
        width: 2em;
        height: 2em;
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
</style>
