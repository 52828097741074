<template>
    <figure class="image is-1by1" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
        <div onclick="window.open('https://www.tiktok.com/@kmso_de', '_blank')" class="pointer-hand">
            <img src="/img/social/tiktok.png" alt="TikTok" :class="{ 'blog-social-icons-mobile': this.isMobile }">
        </div>
    </figure>
</template>

<script>
    export default {
        name: 'Blog-5',
        props: [
            'isMobile',
            'isTablet',
        ],
    }
</script>
