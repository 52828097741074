<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/referenzen.svg" :alt="$t('kmso.references.title_mobile')" class="is-transparent title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.references.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.references.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/referenzen.svg" :alt="$t('kmso.references.title_desktop')" class="is-transparent title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.references.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.references.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_title_1') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_text_1') }}
                    </p>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Referenzen-Kunden"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Referenzen-Kunden"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_title_2') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_text_2') }}
                    </p>
                    <br>
                    <carousel
                        carousel="Referenzen-Kunden-Zitate"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.references.main_text') }}
                        </p>
                    </read-more>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Referenzen-Software"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Referenzen-Software"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section id="webentwicklung" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.web_development_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.web_development_subtitle') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_12_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen15.svg" :alt="$t('kmso.references.erp_system')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <p>{{ $t('kmso.references.web_development_box_12_text') }}</p>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_12_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_12_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_12_tag_3') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_11_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen14.svg" :alt="$t('kmso.references.retail_platform')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>{{ $t('kmso.references.web_development_box_11_text') }}</p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_11_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_11_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_11_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_11_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_10_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen13.svg" :alt="$t('kmso.references.export_platform')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>{{ $t('kmso.references.web_development_box_10_text') }}</p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_10_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_10_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_10_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_10_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_9_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/bya-framework.svg" :alt="$t('kmso.references.bya')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <i18n path="kmso.references.web_development_box_9_text"
                                                      tag="p">
                                                    <a slot="link1" href="https://boost-your-app.com" target="_blank">Boost Your App</a>
                                                    <a slot="link2" href="https://play.google.com" target="_blank" rel="nofollow">Google Play Store</a>
                                                </i18n>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_9_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_9_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_9_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_9_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_7_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen11.svg" :alt="$t('kmso.references.credit_insurance')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.web_development_box_7_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_7_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_7_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_7_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_7_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_8_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen12.svg" :alt="$t('kmso.references.file_storage_management')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <i18n path="kmso.references.web_development_box_8_text"
                                                      tag="p">
                                                    <a slot="link1" href="https://azure.microsoft.com/services/storage/blobs/" target="_blank" rel="nofollow">Azure Blog Storage</a>
                                                    <a slot="link2" href="https://www.microsoft.com" target="_blank" rel="nofollow">Microsoft</a>
                                                </i18n>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_8_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_8_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_8_tag_3') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_6_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen10.svg" :alt="$t('kmso.references.electronic_marketplace')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <i18n path="kmso.references.web_development_box_6_text"
                                                      tag="p">
                                                    <a slot="link1" href="https://europace.de/" target="_blank" rel="nofollow">Europace</a>
                                                </i18n>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_6_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_6_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_6_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_6_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_5_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen9.svg" :alt="$t('kmso.references.e_commerce')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <i18n path="kmso.references.web_development_box_5_text"
                                                      tag="p">
                                                    <a slot="link1" href="https://bagisto.com" target="_blank" rel="nofollow">Bagisto</a>
                                                    <a slot="link2" href="https://laravel.com" target="_blank" rel="nofollow">Laravel</a>
                                                    <a slot="link3" href="https://vuejs.org" target="_blank" rel="nofollow">Vue.js</a>
                                                </i18n>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_5_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_5_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_5_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_5_tag_4') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_5_tag_5') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_4_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen8.svg" :alt="$t('kmso.references.rest_api_credit_management_platform')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.web_development_box_4_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_4_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_4_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_4_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_4_tag_4') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_4_tag_5') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_2_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen2.svg" :alt="$t('kmso.references.securing_liquidity_and_working_capital_management')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.web_development_box_2_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_2_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_2_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_2_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_2_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_1_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen1.svg" :alt="$t('kmso.references.customer_service_center')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.web_development_box_1_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_1_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_1_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_1_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_1_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.web_development_box_3_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen4.svg" :alt="$t('kmso.references.health_and_fitness')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.web_development_box_3_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_3_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_3_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_3_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-2">
                                                <p>{{ $t('kmso.references.web_development_box_3_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
        <section id="mobileapps" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.mobile_apps_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.mobile_apps_subtitle') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-3 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.mobile_apps_box_1_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen3.svg" :alt="$t('kmso.references.surveying_app')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.mobile_apps_box_1_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_1_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_1_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_1_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_1_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-3 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.mobile_apps_box_2_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen6.svg" :alt="$t('kmso.references.sensor_app')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.mobile_apps_box_2_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_2_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_2_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_2_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_2_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-3 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.mobile_apps_box_3_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen7.svg" :alt="$t('kmso.references.weather_app')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.mobile_apps_box_3_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_3_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_3_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-3">
                                                <p>{{ $t('kmso.references.mobile_apps_box_3_tag_3') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
        <section id="desktopanwendungen" class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.desktop_apps_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.desktop_apps_subtitle') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-6 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.references.desktop_apps_box_1_title') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom">
                            <div class="columns is-vcentered">
                                <div class="column is-2">
                                    <img src="/img/referenzen5.svg" :alt="$t('kmso.references.analysis_application')" class="is-horizontal-center">
                                </div>
                                <div class="column is-10" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12">
                                            <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                                       background-shadow="shadow-message-background">
                                                <p>
                                                    {{ $t('kmso.references.desktop_apps_box_1_text') }}
                                                </p>
                                            </read-more>
                                        </div>
                                    </div>
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 tag-header" :class="{ 'has-text-left': this.isMobile, 'text-right-align': !this.isMobile }">
                                            <div class="tag-block border-radius-4 message-header-color-6">
                                                <p>{{ $t('kmso.references.desktop_apps_box_1_tag_1') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-6">
                                                <p>{{ $t('kmso.references.desktop_apps_box_1_tag_2') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-6">
                                                <p>{{ $t('kmso.references.desktop_apps_box_1_tag_3') }}</p>
                                            </div>
                                            <div class="tag-block border-radius-4 message-header-color-6">
                                                <p>{{ $t('kmso.references.desktop_apps_box_1_tag_4') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-right" background-custom="white-background"></Interesse>
        <WaveFooter background-custom="white-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';

    export default {
        name: "Referenzen",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_references"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
