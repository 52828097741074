<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">
                    Login
                </h1>

                <div class="field">
                    <label class="label">E-Mail</label>
                    <div class="control">
                        <input class="input" type="text" v-model="email">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Passwort</label>
                    <div class="control">
                        <input class="input" type="password" v-model="password">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button" v-on:click="login">Login</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                email: null,
                password: null,
                has_error: false
            }
        },
        methods: {
            login() {
                this.$auth.login({
                    params: {
                        email: this.email,
                        password: this.password
                    },
                    error: () => {
                        console.log('There was an error during login.');
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
