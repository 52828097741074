<template>
    <section v-if="this.$pageFullyLoaded">
        <section id="allgemein" class="hero is-medium is-bold white-background foreground hero-main-section-1">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h1 class="title title-custom has-text-centered" :class="{ 'margin-top-contact': this.isMobile && !this.isTablet }">
                        {{ $t('kmso.contact.title') }}
                    </h1>
                    <p class="is-size-5-custom has-text-centered">
                        {{ $t('kmso.contact.subtitle') }}
                    </p>
                    <br>
                    <div class="columns">
                        <div class="column is-4">
                            <a href="tel:+4961739979821" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-border-color-9">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/telefon.svg" :alt="$t('kmso.contact.telephone')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-2"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }" /> {{ $t('kmso.contact.telephone') }}</p>
                                                <p class="is-size-5-custom text-blue">06173 / 997 98 21</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                        <div class="column is-4">
                            <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-border-color-10">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/chat.svg" :alt="$t('kmso.contact.whatsapp')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" /> {{ $t('kmso.contact.whatsapp') }}</p>
                                                <p class="is-size-5-custom text-blue">0159 / 064 79 447</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                        <div class="column is-4">
                            <a href="mailto:kontakt@kmso.de" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-border-color-11">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/email.svg" :alt="$t('kmso.contact.email')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-3"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }" /> {{ $t('kmso.contact.email') }}</p>
                                                <p class="is-size-5-custom text-blue">kontakt@kmso.de</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <h2 class="is-size-4-custom has-text-centered">
                                <strong>
                                    {{ $t('kmso.contact.location') }}
                                </strong>
                            </h2>
                            <br>
                            <div class="border-radius-4 overflow-hidden dark-grey-background">
                                <div class="loader-map-wrapper" :class="{ 'is-active': this.mapLoading }">
                                    <div class="loader is-loading"></div>
                                </div>
                                <div id="lalala" ref="mapkit_map" class="width-100p height-40vh mapkit_map"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <div class="columns is-centered">
                        <div class="column" :class="{ 'is-8': !this.isTablet, 'is-12': this.isTablet }">
                            <h2 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                {{ $t('kmso.contact.contact_form_title') }}
                            </h2>
                            <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                {{ $t('kmso.contact.contact_form_text') }}
                            </p>
                        </div>
                    </div>
                    <contact-form-normal :is-mobile="this.isMobile" :is-tablet="this.isTablet"></contact-form-normal>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import ContactFormNormal from '../components/contact-form-normal';
    import { createMapkit } from 'vue-mapkit';

    export default {
        name: "Kontakt",
        components: {
            WaveFooter,
            WaveBottom,
            ContactFormNormal,
        },
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                map: null,
                mapLoading: true,
                mapProcessStarted: null,
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_contact"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        updated() {
            if (!this.mapProcessStarted) {
                const mapkitMap = this.$refs.mapkit_map;

                if (mapkitMap != null) {
                    this.mapProcessStarted = true;

                    try {
                        createMapkit(mapkitMap, { language: this.$i18n.locale }).then(map => {
                            this.map = map;
                            const center = new window.mapkit.Coordinate(50.15497970581055, 8.546648979187012);

                            this.map.center = center;
                            this.map.cameraDistance = 15000;

                            const annotation = new window.mapkit.MarkerAnnotation(center, {
                                title: "KMSO IT-Dienstleistungen GmbH",
                                subtitle: "Steinbacher Straße 2, 65760 Eschborn",
                                glyphText: "KMSO",
                                color: "#00917c",
                                displayPriority: 1000
                            });
                            this.map.addAnnotation(annotation);

                            this.mapLoading = null;
                        });
                    } catch (e) {
                        //
                    }
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>
    .loader-map-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s;
    }
    .loader-map-wrapper .loader {
        width: 80px;
        height: 80px;
    }
    .loader-map-wrapper.is-active {
        opacity: 1;
    }
</style>
