<template>
    <blog-editor
        :post-id="$route.params.id"
        is-edit="true"
    />
</template>

<script>
    import BlogEditor from "./BlogEditor";

    export default {
        name: "EditPost",
        components: {
            BlogEditor
        },
        mounted() {
            console.log(this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>
