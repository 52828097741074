<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/rocket-clouds.svg" :alt="$t('kmso.home.clouds')" class="is-transparent title-img">
                    <img ref="rocket" src="/img/rocket.svg" :alt="$t('kmso.home.rocket')" class="is-transparent title-img rocket-transition">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.home.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white has-text-left content">
                                <ul class="hero-margin-left">
                                    <li>
                                        {{ $t('kmso.home.subtitle_mobile_1') }}
                                    </li>
                                    <li>
                                        {{ $t('kmso.home.subtitle_mobile_2') }}
                                    </li>
                                    <li>
                                        {{ $t('kmso.home.subtitle_mobile_3') }}
                                    </li>
                                </ul>
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/rocket-clouds.svg" :alt="$t('kmso.home.clouds')" class="is-transparent title-img">
                <img ref="rocket" src="/img/rocket.svg" :alt="$t('kmso.home.rocket')" class="is-transparent title-img rocket-transition">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.home.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white has-text-left content">
                                <ul>
                                    <li>
                                        {{ $t('kmso.home.subtitle_desktop_1') }}
                                    </li>
                                    <li>
                                        {{ $t('kmso.home.subtitle_desktop_2') }}
                                    </li>
                                    <li>
                                        {{ $t('kmso.home.subtitle_desktop_3') }}
                                    </li>
                                </ul>
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.home.project_management_title') }}
                    </h3>
                    <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet  }">
                        <router-link class="is-size-4-custom" :to="{ name: 'projekt-management' }">
                            {{ $t('kmso.home.project_management_url') }} &#8811;
                        </router-link>
                    </div>
                    <br>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                        background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.home.project_management_text') }}
                        </p>
                    </read-more>
                    <br>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.home.project_management_text_1') }}
                        </strong>
                    </h4>
                    <br>
                    <carousel
                        carousel="Startseite-Projektmanagement-Circle"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <br v-if="this.isMobile">
                    <br>
                    <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>{{ $t('kmso.home.project_management_title_1') }}</strong>
                    </h4>
                    <br>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.home.project_management_main_text') }}
                        </p>
                    </read-more>
                    <br>
                    <div v-if="!this.isTablet">
                        <carousel
                            carousel="Startseite-Projektmanagement-1"
                            :is-mobile="this.isMobile"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel v-if="!this.isMobile"
                                  carousel="Startseite-Projektmanagement-2"
                                  :is-mobile="this.isMobile"
                                  :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                    <div v-else>
                        <carousel
                            carousel="Startseite-Projektmanagement-1"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                        <carousel
                            carousel="Startseite-Projektmanagement-2"
                            :is-mobile="false"
                            :is-tablet="this.isTablet">
                        </carousel>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.home.software_development_title') }}
                    </h3>
                    <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <router-link class="is-size-4-custom" :to="{ name: 'software-entwicklung' }">
                            {{ $t('kmso.home.software_development_url') }} &#8811;
                        </router-link>
                    </div>
                    <br>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-grey-background">
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.home.software_development_text') }}
                    </p>
                    </read-more>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Startseite-Software"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Startseite-Software"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_title_1') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_text_1') }}
                    </p>
                    <br>
                    <carousel
                        v-if="!this.isTablet"
                        carousel="Referenzen-Kunden"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                    <carousel
                        v-else
                        carousel="Referenzen-Kunden"
                        :is-mobile="false"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_title_2') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.references.main_text_2') }}
                    </p>
                    <br>
                    <carousel
                        carousel="Referenzen-Kunden-Zitate"
                        :is-mobile="this.isMobile"
                        :is-tablet="this.isTablet">
                    </carousel>
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-right" background-custom="white-background" />
        <WaveFooter background-custom="white-background" />
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';

    export default {
        name: "Home",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                lastWindowHeight: 0,
                rocketPositionTop: 0,
                rocketPositionMoved: null,
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_home"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
        },
        methods: {
            getRocket() {
              return this.$refs.rocket;
            },
            setWindowHeight() {
                this.lastWindowHeight = window.innerHeight;
            },
            windowScrollEvent() {
                window.removeEventListener('scroll', this.windowScrollEvent);

                const rocket = this.getRocket();

                if (!this.rocketPositionMoved) {
                    this.rocketPositionMoved = true;
                    this.rocketPositionTop = rocket.offsetTop;
                    rocket.style.top = `${this.rocketPositionTop}px`;
                }

                const verticalPosition = this.$getScrollTop();

                if (verticalPosition > 0) {
                    rocket.style.top = `${this.rocketPositionTop - Math.round(verticalPosition * 1.5)}px`;
                } else {
                    rocket.style.top = `${this.rocketPositionTop}px`;
                }

                setTimeout( () => {
                    window.addEventListener('scroll', this.windowScrollEvent);
                }, 10);
            },
            windowResizeEvent() {
                const rocket = this.getRocket();

                const deltaWindowHeight = window.innerHeight - this.lastWindowHeight;
                this.lastWindowHeight = window.innerHeight;

                if (rocket.style.top === null || rocket.style.top === "") {
                    this.rocketPositionTop = rocket.offsetTop;
                    rocket.style.top = null;
                } else {
                    let rocketNewPosition = rocket.offsetTop + deltaWindowHeight;
                    this.rocketPositionTop = this.rocketPositionTop + deltaWindowHeight;

                    rocket.style.top = `${rocketNewPosition}px`;
                }
            },
        },
        created () {
            this.setWindowHeight();

            window.addEventListener('scroll', this.windowScrollEvent);

            if (!this.isMobile) {
                window.addEventListener('resize', this.windowResizeEvent);
            }
        },
        destroyed () {
            window.removeEventListener('scroll', this.windowScrollEvent);

            if (!this.isMobile) {
                window.removeEventListener('resize', this.windowResizeEvent);
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
