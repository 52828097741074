<template>
    <div>
        <article class="message">
            <div class="message-header message-header-color-9 is-size-5-custom message-header-center display-flex pointer-hand"
                 :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }"
                 @click="this.toggleJobOffer">
                <slot name="header"></slot>
            </div>
            <div ref="jobOfferContainer" class="message-body is-size-5-custom initialContentHeight transition-jobs">
                <div class="columns is-vcentered message-padding">
                    <div class="column is-2">
                        <slot name="image"></slot>
                    </div>
                    <div class="column is-10 has-text-left">
                        <div class="content">
                            <slot name="content"></slot>
                            <br>
                            <p>
                                {{ $t('kmso.job_offer.text') }}
                                <a href = "mailto: kontakt@kmso.de">kontakt@kmso.de</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="message-footer message-header-color-9 is-size-5-custom pointer-hand"
                 @click="this.toggleJobOffer">
                <div class="is-vertical-center add-horizontal-center message-min-text-footer-height">
                     <span ref="arrowOpenClose" class="arrowOpenClose" :class="{ 'active': this.jobOffer }">
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: "Job-Offer",
        props: [
            'isMobile',
            'isTablet',
            'showOffer',
        ],
        data() {
            return {
                jobOffer: this.showOffer,
            }
        },
        methods: {
            toggleJobOffer() {
                this.jobOffer = !this.jobOffer;
                this.moveJobOffer();
            },
            moveJobOffer() {
                if (this.jobOffer) {
                    this.$refs.jobOfferContainer.style.setProperty('max-height', this.$refs.jobOfferContainer.scrollHeight + 'px');
                } else {
                    this.$refs.jobOfferContainer.style.setProperty('max-height', '0px');
                }
            }
        },
        updated() {
            this.moveJobOffer();
        },
        mounted() {
            setTimeout(() => {
                this.moveJobOffer();
            }, 100);
        }
    }
</script>

<style scoped>
    .message-body {
        padding: unset !important;
    }
    .message-padding {
        padding: 1.25em 1.5em;
    }

    .transition-jobs {
        transition: max-height .3s ease;
    }
    .initialContentHeight {
        overflow: hidden;
    }

    .arrowOpenClose {
        position: relative;
        width: 1.5em;
        height: .5em;
    }
    .arrowOpenClose span {
        position: absolute;
        width: 1em;
        height: .3em;
        background-color: #fff;
        transition: all .3s ease;
    }
    .arrowOpenClose span:first-of-type {
        transform: rotate(45deg);
        left: 0;
    }
    .arrowOpenClose span:last-of-type {
        transform: rotate(-45deg);
        right: 0;
    }
    .arrowOpenClose.active span:first-of-type {
        transform: rotate(-45deg);
    }
    .arrowOpenClose.active span:last-of-type {
        transform: rotate(45deg);
    }
</style>
