<template>
    <section>
        <b-table
            :data="data"
            paginated
            per-page="15"
        >
            <template slot-scope="props">
                <b-table-column
                    field="name"
                    label="Name"
                    sortable
                    width="300"
                >
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column
                    field="headline"
                    label="Beschreibung der Tätigkeit"
                    sortable
                >
                    {{ props.row.description }}
                </b-table-column>

                <b-table-column
                    field="time_logged"
                    label="Dauer"
                    width="100"
                >
                    {{ props.row.time_logged }}
                </b-table-column>

                <b-table-column
                    field="date"
                    label="Datum"
                    sortable
                    width="150"
                >
                    {{ props.row.date }}
                </b-table-column>

                <b-table-column
                    label="Aktionen"
                    width="150"
                >
                    <a class="button is-small" :href="'/backend/timerecording/edit-timerecord/' + props.row.id">
                        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'pen' }"/>
                    </a>
                    <button class="button is-small" v-on:click="openDeleteModal(props.row.id)">
                        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'trash' }"/>
                    </button>
                </b-table-column>
            </template>
        </b-table>

        <div class="columns">
            <div class="column is-narrow">
                <div class="field">
                    <label class="label">Benutzer</label>
                    <div class="select">
                        <select v-model="selectedUser">
                            <option v-for="user in users" :value="user.id">{{ user.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="column">
                <label class="label">Aktion</label>
                <div class="field">
                    <button class="button" v-on:click="showUserSummary">Anzeigen</button>
                </div>
            </div>
        </div>

        <b-table
            v-if="userSummaryData !== null"
            :data="userSummaryData"
            paginated
            per-page="15"
        >
            <template slot-scope="props">
                <b-table-column
                    field="date"
                    label="Jahr und Monat"
                    sortable
                >
                    {{ props.row.date }}
                </b-table-column>

                <b-table-column
                    field="entries"
                    label="Einträge"
                    sortable
                >
                    {{ props.row.entries }}
                </b-table-column>

                <b-table-column
                    field="time_logged"
                    label="Dauer"
                >
                    {{ props.row.time_logged }}
                </b-table-column>

                <b-table-column
                    label="Aktionen"
                    width="150"
                >
                    <a class="button is-small" :href="'/backend/timerecording/month-overview/' + props.row.user_id + '/' + props.row.date">
                        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'eye' }"/>
                    </a>
                </b-table-column>
            </template>
        </b-table>


        <div class="modal" :class="{ 'is-active': deleteModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Zeiterfassung löschen</p>
                    <button class="delete" aria-label="close" v-on:click="closeDeleteModal"/>
                </header>
                <section class="modal-card-body">
                    Soll die Zeiterfassung wirklich gelöscht werden?
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" v-on:click="deleteTimerecord">Löschen</button>
                    <button class="button is-success" v-on:click="closeDeleteModal">Abbrechen</button>
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
export default {
name: "Administration",
    data() {
        return {
            description: null,
            hours: 0,
            minutes: 0,
            selected: null,
            descriptionValid: true,
            timeValid: true,
            data: [],
            deleteModal: false,
            deleteTimerecordId: null,
            users: [],
            selectedUser: null,
            userSummaryData: null,
        }
    },
    mounted() {
        this.loadTimerecords();
        this.loadUsers();
    },
    methods: {
        showUserSummary() {
            if(this.selectedUser !== null) {
                window.axios
                    .get('backend/timerecording/load-user-summary/' + this.selectedUser, {
                        headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                    })
                    .then(response => {
                        this.userSummaryData = response.data;
                    })
                    .catch(error => {
                        console.log(error.response)
                    });
            }
        },
        loadUsers() {
            window.axios
                .get('backend/timerecording/load-users', {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    this.users = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
        loadTimerecords() {
            window.axios
                .get('backend/timerecording/timerecords-for-administrator', {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()}
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
        openDeleteModal(id) {
            this.deleteModal = true;
            this.deleteTimerecordId = id;
        },
        closeDeleteModal() {
            this.deleteModal = false;
            this.deleteTimerecordId = null;
        },
        deleteTimerecord() {
            window.axios
                .post('backend/timerecording/delete-timerecord', {
                    headers: {'Authorization': 'Bearer ' + this.$auth.token()},
                    id: this.deleteTimerecordId
                })
                .then(() => {
                    window.location = '/backend/timerecording/administration';
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
}
</script>

<style scoped>

</style>
