<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/leistungen.svg" :alt="$t('kmso.services.title_mobile')" class="is-transparent title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.services.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.services.subtitle_mobile_1') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/leistungen.svg" :alt="$t('kmso.services.title_desktop')" class="is-transparent title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.services.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.services.subtitle_desktop_1') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.services.main_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.services.main_text') }}
                        </p>
                    </read-more>
                    <br>
                    <div v-if="!this.isTablet" class="columns">
                        <div class="column dark-grey-background border-radius-4">
                            <h4 class="is-size-4-custom"
                               :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <strong>
                                    {{ $t('kmso.services.main_box_1_title') }}
                                </strong>
                            </h4>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <router-link class="is-size-4-custom" :to="{ name: 'projekt-management' }">
                                    {{ $t('kmso.services.main_box_1_subtitle') }} &#8811;
                                </router-link>
                            </div>
                            <br>
                            <carousel
                                carousel="Leistungen-Projektmanagement"
                                :is-mobile="this.isMobile"
                                :is-tablet="this.isTablet">
                            </carousel>
                        </div>
                        <div class="space-between-columns"></div>
                        <div class="column dark-grey-background border-radius-4">
                            <h4 class="is-size-4-custom"
                               :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <strong>
                                    {{ $t('kmso.services.main_box_2_title') }}
                                </strong>
                            </h4>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <router-link class="is-size-4-custom" :to="{ name: 'software-entwicklung' }">
                                    {{ $t('kmso.services.main_box_2_subtitle') }} &#8811;
                                </router-link>
                            </div>
                            <br>
                            <carousel
                                carousel="Leistungen-Software"
                                :is-mobile="this.isMobile"
                                :is-tablet="this.isTablet">
                            </carousel>
                        </div>
                    </div>
                    <div v-else>
                        <div class="columns">
                            <div class="column is-12 dark-grey-background border-radius-4">
                                <h4 class="is-size-4-custom"
                                    :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>
                                        {{ $t('kmso.services.main_box_1_title') }}
                                    </strong>
                                </h4>
                                <br>
                                <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <router-link class="is-size-4-custom" :to="{ name: 'projekt-management' }">
                                        {{ $t('kmso.services.main_box_1_subtitle') }} &#8811;
                                    </router-link>
                                </div>
                                <br>
                                <carousel
                                    carousel="Leistungen-Projektmanagement"
                                    :is-mobile="false"
                                    :is-tablet="this.isTablet">
                                </carousel>
                            </div>
                        </div>
                        <div class="space-between-columns"></div>
                        <div class="columns">
                            <div class="column is-12 dark-grey-background border-radius-4">
                                <h4 class="is-size-4-custom"
                                    :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>
                                        {{ $t('kmso.services.main_box_2_title') }}
                                    </strong>
                                </h4>
                                <br>
                                <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <router-link class="is-size-4-custom" :to="{ name: 'software-entwicklung' }">
                                        {{ $t('kmso.services.main_box_2_subtitle') }} &#8811;
                                    </router-link>
                                </div>
                                <br>
                                <carousel
                                    carousel="Leistungen-Software"
                                    :is-mobile="false"
                                    :is-tablet="this.isTablet">
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-left" background-custom="grey-background"></Interesse>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';

    export default {
        name: "Leistungen",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_services"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
